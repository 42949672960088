import { types, getRoot, getEnv, flow } from "mobx-state-tree";

import Contact from "./contact";

const ContactGroup = types
  .model("ContactGroup", {
    id: types.identifier,
    name: types.string,
    contacts: types.array(Contact),
  })
  .views(self => ({
    get contactCount() {
      return self.contacts.length;
    },
  }));

export default types
  .model("Notifications", {
    contactGroups: types.optional(types.array(ContactGroup), []),
    selectedContacts: types.optional(types.map(types.reference(Contact)), {}),
    subject: "New Vendo Release",
    headline: "We have great news!",
    message: "Vendo 1.1x has been released and is available to download.",
    actionButtonLabel: "Learn more",
    actionButtonUrl: "https://www.touchtech.com/releases",
  })
  .views(self => ({
    get isLoading() {
      return getRoot(self).pendingActions.has("fetchContacts");
    },
    get isSending() {
      return getRoot(self).pendingActions.has("sendNotifications");
    },
    get canSend() {
      return !self.isSending && self.selectedEmails.length > 0;
    },
    get groupCount() {
      return self.groups.length;
    },
    get contactCount() {
      return self.groups.reduce((count, group) => {
        return count + group.contactCount;
      }, 0);
    },
    get selectedEmails() {
      const emailSet = [...self.selectedContacts.values()].reduce((result, contact) => {
        result.add(contact.email);
        return result;
      }, new Set());

      return [...emailSet];
    },
  }))
  .actions(self => ({
    reset() {
      self.contactGroups = [];
      self.resetSelectedContacts();
    },
    /**
     * Fetches a list of contact groups
     */
    fetchContacts() {
      const { cancellationToken, cancel } = getEnv(
        self
      ).connector.getCancellationTokenSource();

      const action = flow(function* fetch() {
        self.contactGroups = yield getEnv(self).connector.fetchContacts(
          cancellationToken
        );
      });

      return getRoot(self).runAction("fetchContacts", action, cancel);
    },
    send() {
      if (self.isSending) {
        return;
      }

      const { cancellationToken, cancel } = getEnv(
        self
      ).connector.getCancellationTokenSource();

      const action = flow(function* fetch() {
        try {
          const actionButtonUrl = self.actionButtonUrl || undefined;
          const actionButtonLabel = self.actionButtonLabel || undefined;
          
          yield getEnv(self).connector.sendEmail(
            self.selectedEmails,
            self.subject,
            self.headline,
            self.message,
            actionButtonLabel,
            actionButtonUrl,
            cancellationToken
          );

          getRoot(self).createToast(
            `Notification to ${self.selectedEmails.length} contacts has been sent successfully.`
          );

          self.resetSelectedContacts();
        } catch(err) {
          getRoot(self).createToast(
            `Could not send notification - ${err.message}`
          );
        }
      });

      return getRoot(self).runAction("sendNotifications", action, cancel);
    },
    setSubject(subject) {
      self.subject = subject;
    },
    setHeadline(headline) {
      self.headline = headline;
    },
    setMessage(message) {
      self.message = message;
    },
    setActionButtonLabel(actionButtonLabel) {
      self.actionButtonLabel = actionButtonLabel;
    },
    setActionButtonUrl(actionButtonUrl) {
      self.actionButtonUrl = actionButtonUrl;
    },
    selectContacts(contacts) {
      for (const contact of contacts) {
        self.selectedContacts.set(contact.id, contact);
      }
    },
    unselectContacts(contacts) {
      for (const contact of contacts) {
        self.selectedContacts.delete(contact.id);
      }
    },
    resetSelectedContacts() {
      self.selectedContacts = {};
    }
  }));
