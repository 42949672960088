import React from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import { Box } from "grommet";

import ScreenSummary from "./ScreenSummary";

class Screens extends React.Component {
  render() {
    const screens = this.props.screens.map(screen => (
      <ScreenSummary key={screen.id} screen={screen} />
    ));

    return <Box gap="hair">{screens}</Box>
  }
}

Screens.propTypes = {
  screens: PropTypes.arrayOf(PropTypes.object).isRequired,
  sortKey: PropTypes.string.isRequired
};

export default observer(Screens);
