import React from "react";
import { observer } from "mobx-react";
import ReactJson from "react-json-view";
import { Link } from "react-router-dom";
import { Box, Heading, Text, Grid, Image } from "grommet";
import qs from "qs";

import ensureStore from "../store";
import SideBar from "../containers/SideBar";
import OptionSelector from "../components/OptionSelector";
import "./Menu.css";

class Menus extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuId: null,
      menuItemId: null,
      topMenuItemId: null,
      locale: null,
      languageCode: null,
      countryCode: null
    };

    this.handleLocaleChanged = this.handleLocaleChanged.bind(this);
    this.store = ensureStore();
  }

  componentDidMount() {
    this.parseUrl(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.parseUrl(nextProps);
  }

  parseUrl(props) {
    const { menuId, menuItemId, topMenuItemId } = props.match.params;

    const { locale } = qs.parse(props.location.search, {
      ignoreQueryPrefix: true
    });

    const [languageCode, countryCode] = locale ? locale.split("-") : [];

    if (menuId && menuId !== this.state.menuId) {
      this.store.menus.fetchById(menuId);
    }

    this.setState({
      menuId,
      menuItemId,
      topMenuItemId,
      locale,
      languageCode,
      countryCode
    });
  }

  handleLocaleChanged({ key: locale }) {
    const { currency } = this.state;
    this.props.history.replace(`?${qs.stringify({ currency, locale })}`);
  }

  renderHeroImage({ heroImage }) {
    if (!heroImage) {
      return null;
    }

    return <Image src={heroImage.defaultValue.url} alt="Hero" />;
  }

  renderTopMenu(menu) {
    const menuItems = menu.items.map(({ $type, id, tileImage }) => {
      const { menuId } = this.state;
      //const isSelected = id === menuItemId || id === topMenuItemId;

      const path =
        $type === "TopMenuItem"
          ? `/customer/menus/${menuId}/sub-menu/${id}`
          : `/customer/menus/${menuId}/item/${id}`;

      //className={`tile-image-container${isSelected ? " selected" : ""}`}

      return (
        <Link key={id} to={path} className="menu-item tile-image">
          <Box>
            <Image src={tileImage.defaultValue.url} alt="Top menu" />
          </Box>
        </Link>
      );
    });

    return (
      <Grid
        gridArea="firstLevel"
        areas={[
          { name: "hero", start: [0, 0], end: [0, 0] },
          { name: "secondLevel", start: [1, 0], end: [1, 0] },
          { name: "thirdLevel", start: [2, 0], end: [2, 0] },
          { name: "json", start: [0, 1], end: [2, 1] }
        ]}
        columns={["1/3", "1/3", "1/3"]}
        rows={["auto", "auto"]}
        gap="small"
      >
        {this.renderHeroImage(menu)}
        <Box>{menuItems}</Box>
      </Grid>
    );
  }

  renderSelectedMenuItem(menuItem) {
    if (!menuItem) {
      return null;
    }

    switch (menuItem.$type) {
      case "TopMenuItem":
        return this.renderTopMenuItem(menuItem);
      case "SubMenuItem":
        return this.renderSubMenuItem(menuItem);
      case "WebMenuItem":
        return this.renderWebMenuItem(menuItem);
      default:
        return null;
    }
  }

  renderTopMenuItem(menuItem) {
    const menuItems = menuItem.children.map(({ id, tileImage }) => {
      const { menuId, menuItemId, topMenuItemId } = this.state;
      const path = `/customer/menus/${menuId}/sub-menu/${topMenuItemId}/item/${id}`;

      return (
        <Link key={id} to={path} className="menu-item tile-image">
          <Box
            className={`tile-image-container${
              id === menuItemId ? " selected" : ""
            }`}
          >
            <Image src={tileImage.defaultValue.url} alt="Menu item" />
          </Box>
        </Link>
      );
    });

    return <Box gridArea="secondLevel">{menuItems}</Box>;
  }

  renderSubMenuItem({ definition, filters }) {
    const filterItems = filters.map((value, i) => <div key={i}>{value}</div>);

    return (
      <Box gridArea="thirdLevel">
        <Heading level={3}>Filters</Heading>
        {filterItems}
        <br />
        <Heading level={3}>Categories</Heading>
        {this.renderDefinition(definition)}
      </Box>
    );
  }

  renderDefinition({ $type, definitions, categorySlug }) {
    switch ($type) {
      case "AggregateListingDefinition":
        const items = definitions.map((definition, i) => (
          <div className="menu-item-definition" key={i}>
            {this.renderDefinition(definition)}
          </div>
        ));

        return <div>{items}</div>;
      case "CategoryListingDefinition":
        return <div>{categorySlug}</div>;
      default:
        return null;
    }
  }

  renderWebMenuItem(menuItem) {
    return (
      <Box gridArea="secondaryLevel">
        <Heading level={3}>Web URL</Heading>
        <Text>{menuItem.url}</Text>
        <code>{menuItem.cssOverride}</code>
      </Box>
    );
  }

  renderJson({ json }) {
    return (
      <Box gridArea="json">
        <ReactJson
          name="menu"
          theme="bright:inverted"
          collapsed={true}
          displayObjectSize={false}
          displayDataTypes={false}
          src={json}
        />
      </Box>
    );
  }

  render() {
    const { localeOptions } = this.store;
    const menu = this.store.menus.items.get(this.state.menuId);

    if (!menu) {
      return null;
    }

    const topMenuItem = menu.items.find(
      menuItem => menuItem.id === this.state.topMenuItemId
    );

    const menuItem = topMenuItem
      ? topMenuItem.children.find(
          menuItem => menuItem.id === this.state.menuItemId
        )
      : menu.items.find(menuItem => menuItem.id === this.state.menuItemId);

    return (
      <React.Fragment>
        <SideBar history={this.props.history} location={this.props.location} />
        <Box
          direction="column"
          background={{ color: "light-2" }}
          pad={{ horizontal: "xlarge", vertical: "medium" }}
          gap="small"
        >
          <Box
            direction="row"
            pad={{ top: "medium", bottom: "xlarge" }}
            alignSelf="end"
          >
            <Heading
              level={1}
              size="large"
              style={{ fontWeight: "300", letterSpacing: "-0.033em" }}
            >
              {menu.id}
            </Heading>
          </Box>
          <Box direction="row" fill="horizontal" gap="xsmall">
            <OptionSelector
              options={localeOptions}
              onChange={this.handleLocaleChanged}
              value={this.state.locale}
              placeholder="Locale"
            />
          </Box>
          <Grid
            areas={[
              { name: "firstLevel", start: [0, 0], end: [0, 0] },
              { name: "secondLevel", start: [1, 0], end: [1, 0] },
              { name: "thirdLevel", start: [2, 0], end: [2, 0] },
              { name: "json", start: [0, 1], end: [2, 1] }
            ]}
            columns={["1/3", "1/3", "1/3"]}
            rows={["auto", "auto"]}
            gap="small"
          >
            {this.renderTopMenu(menu)}
            {this.renderSelectedMenuItem(topMenuItem)}
            {this.renderSelectedMenuItem(menuItem)}
            {this.renderJson(menu)}
          </Grid>
        </Box>
      </React.Fragment>
    );
  }
}

export default observer(Menus);
