import React from "react";
import { observer } from "mobx-react";
import { Redirect, Route } from "react-router-dom";

import ensureStore from "../store";
import Map from "../containers/Map";
import Menus from "../containers/Menus";
import Menu from "../containers/Menu";
import Screens from "../containers/Screens";
import Screen from "../containers/Screen";
import Sessions from "../containers/Sessions";
import Session from "../containers/Session";
import Products from "../containers/Products";
import Insights from "../containers/Insights";
import Checkout from "../containers/Checkout";
import Checkouts from "../containers/Checkouts";
import Footer from "../containers/Footer";

class CustomerPortal extends React.Component {
  constructor(props) {
    super(props);

    this.store = ensureStore(props.store);
  }

  render() {
    const { location: referrer } = this.props;

    if (!this.store.identity.isSignedIn) {
      return <Redirect to={{ pathname: "/", state: { referrer } }} />;
    }

    if (this.store.identity.isAdmin) {
      return <Redirect to={{ pathname: "/admin", state: { referrer } }} />;
    }

    return (
      <div className="container">
        <Route exact path="/customer" component={Screens} />
        <Route exact path="/customer/map" component={Map} />
        <Route exact path="/customer/insights" component={Insights} />
        <Route exact path="/customer/screens" component={Screens} />
        <Route exact path="/customer/screens/:screenId" component={Screen} />
        <Route exact path="/customer/sessions" component={Sessions} />
        <Route exact path="/customer/sessions/:sessionId" component={Session} />
        <Route exact path="/customer/products" component={Products} />
        <Route
          exact
          path="/customer/products/:articleNumber"
          component={Products}
        />
        <Route exact path="/customer/checkouts" component={Checkouts} />
        <Route
          exact
          path="/customer/checkouts/:checkoutId"
          component={Checkout}
        />
        <Route exact path="/customer/menus" component={Menus} />
        <Route exact path="/customer/menus/:menuId" component={Menu} />
        <Route
          exact
          path="/customer/menus/:menuId/item/:menuItemId"
          component={Menu}
        />
        <Route
          exact
          path="/customer/menus/:menuId/sub-menu/:topMenuItemId"
          component={Menu}
        />
        <Route
          exact
          path="/customer/menus/:menuId/sub-menu/:topMenuItemId/item/:menuItemId"
          component={Menu}
        />
        <Footer />
      </div>
    );
  }
}

export default observer(CustomerPortal);
