import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import Moment from "react-moment";
import { Box, Heading, Text } from "grommet";

import ListRow from "../ListRow";
import Metric from "../Metric";
import MetricRow from "../MetricRow";

class SessionSummary extends React.Component {
  render() {
    const { session } = this.props;

    if (!session) {
      return null;
    }

    const {
      id,
      timestamp,
      selectedItemCount,
      basketSkuCount,
      totalPrice,
      currencyCode
    } = session;

    return (
      <ListRow link={`/customer/sessions/${id}`}>
        <Box>
          <Heading level={1} size="small">
            <Moment format="LLL">{timestamp}</Moment>
          </Heading>
          <Text>
            <Moment format="LLL">{timestamp}</Moment>
          </Text>
        </Box>
        <MetricRow>
          <Metric value={selectedItemCount} plain />
          <Metric value={basketSkuCount} plain />
          <Metric value={totalPrice} unit={currencyCode} plain />
        </MetricRow>
      </ListRow>
    );
  }
}

SessionSummary.propTypes = {
  session: PropTypes.object
};

export default observer(SessionSummary);
