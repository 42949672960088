const cities = [
  "London",
  "New York",
  "Berlin",
  "Stockholm",
  "Paris",
  "Madrid",
  "Amsterdam",
  "Copenhagen",
  "Vienna",
  "Rome",
  "Oslo",
  "Gothenburg",
  "Malmö",
  "Munich",
  "Hamburg",
  "Helsinki",
];

function obfuscateScreen(screen) {
  const name = `${getRandomItem(cities)} ${getRandomNumber()}`;
  return { ...screen, name };
}

function getRandomItem(items) {
  return items[Math.floor(Math.random() * items.length)];
}

function getRandomNumber(minNumber = 1, maxNumber = 10) {
  return Math.floor(Math.random() * maxNumber) + minNumber;
}

export default {
  screen: obfuscateScreen
}