import md5 from "md5";
import LRU from "lru-cache";

import errors from "./errors";

export default function create(url, client, auth) {
  return new Api(url, client, auth);
}

function Api(url, client, auth) {
  this.REQUEST_CANCELLED = errors.REQUEST_CANCELLED;

  if (!url) {
    throw new TypeError(`Invalid API URL [${url}]`);
  }

  if (!client) {
    throw new TypeError(`Invalid client instance`);
  }

  if (!auth) {
    throw new TypeError(`Invalid auth instance`);
  }

  this.client = client;
  this.auth = auth;
  this.url = url;

  this.cache = new LRU({
    max: 1000,
    maxAge: 3600
  });
}

Api.prototype.get = function _get(options) {
  return this.request({ ...options, method: "GET" });
};

Api.prototype.post = function _post(options) {
  return this.request({ ...options, method: "POST" });
};

Api.prototype.put = function _put(options) {
  return this.request({ ...options, method: "PUT" });
};

Api.prototype.patch = function _patch(options) {
  return this.request({ ...options, method: "PATCH" });
};

Api.prototype.delete = function _delete(options) {
  return this.request({ ...options, method: "DELETE" });
};

Api.prototype.request = async function _request(options) {
  this.auth.ensureValidAccessToken();

  const request = {
    url: `${this.url}${options.route}`,
    method: options.method,
    params: options.params,
    data: options.data,
    headers: {}
  };

  if (this.auth.accessToken) {
    request.headers["Authorization"] = `Bearer ${this.auth.accessToken}`;
  }

  const hash = md5(JSON.stringify(request));

  // Check if promise for identical GET request exists in cache
  // Return existing promise in that case so that entities are only fetched once
  if (request.method === "GET" && this.cache.has(hash)) {
    return this.cache.get(hash);
  }

  if (options.cancellationToken) {
    request.cancelToken = options.cancellationToken;
  }

  const requestPromise = this.client(request)
    .then(res => res.data)
    .catch(err => {
      if (this.client.isCancel(err)) {
        throw new Error(this.REQUEST_CANCELLED);
      } else {
        throw err;
      }
    });

  if (request.method === "GET") {
    this.cache.set(hash, requestPromise, options.cacheMaxAge);
  }

  return requestPromise;
};
