import { types } from "mobx-state-tree";
import { isObservableArray } from "mobx";

const Option = types.model("Option", {
  key: types.identifier,
  displayName: types.string
});

const Constraints = types
  .model("Constraints", {
    type: types.maybe(types.string),
    min: types.maybe(types.number),
    max: types.maybe(types.number),
    step: types.maybe(types.number),
    options: types.maybe(types.array(Option)),
    allowMultiple: types.maybe(types.boolean)
  })
  .views(self => ({
    get nativeOptions() {
      if (!self.options) {
        return null;
      }

      return self.options.map(({ displayName, key }) => ({
        label: displayName,
        value: key
      }));
    }
  }));

export default types
  .model("Setting", {
    key: types.identifier,
    value: types.maybe(
      types.union(
        types.string,
        types.number,
        types.boolean,
        types.null,
        types.array(types.string)
      )
    ),
    displayName: types.maybe(types.string),
    constraints: Constraints
  })
  .views(self => ({
    get nativeValue() {
      // Create shallow copy array
      return isObservableArray(self.value) ? self.value.slice() : self.value;
    }
  }));
