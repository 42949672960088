import React from "react";
import { observer } from "mobx-react";
import moment from "moment";
import ReactMapboxGl, {
  Layer,
  Feature,
  Popup,
  MapContext
} from "react-mapbox-gl";

import ensureStore from "../store";
import SideBar from "./SideBar";

const Mapbox = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_KEY
});

class Map extends React.Component {
  constructor(props) {
    super(props);

    this.handleSelectScreen = this.handleSelectScreen.bind(this);
    this.handleUnselectScreen = this.handleUnselectScreen.bind(this);

    this.store = ensureStore();
    this.state = { selectedScreen: null };
    this.didInitializeMap = false;
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    this.fetchData();
  }

  fetchData() {
    setImmediate(() => {
      this.store.screens.fetchLatestHeartbeats();
    });
  }

  handleSelectScreen({
    feature: {
      properties: { screen }
    }
  }) {
    this.setState({ selectedScreen: JSON.parse(screen) });
  }

  handleUnselectScreen() {
    this.setState({ selectedScreen: null });
  }

  render() {
    return (
      <React.Fragment>
        <SideBar history={this.props.history} location={this.props.location} />
        <Mapbox
          style="mapbox://styles/touchtechab/ck5o7nlxs1iem1ioar6raf47d"
          containerStyle={{
            height: "100vh",
            width: "100%"
          }}
        >
          <MapContext.Consumer>
            {map => {
              if (this.didInitializeMap) {
                return;
              }

              map.flyTo({ center: [6.96, 50.92], zoom: 3 });
              this.didInitializeMap = true;
            }}
          </MapContext.Consumer>
          <Layer
            type="symbol"
            id="marker"
            layout={{ "icon-image": "store", "icon-size": 1.2 }}
          >
            {this.store.screens.sortedHeartbeats
              .filter(({ geopositionHash }) => !!geopositionHash)
              .map(
                ({
                  screenId,
                  screenName,
                  customerId,
                  timestamp,
                  geopositionLongitude,
                  geopositionLatitude
                }) => {
                  return (
                    <Feature
                      key={screenId}
                      coordinates={[geopositionLongitude, geopositionLatitude]}
                      properties={{
                        screen: {
                          screenId,
                          screenName,
                          customerId,
                          timestamp,
                          geopositionLongitude,
                          geopositionLatitude
                        }
                      }}
                      onMouseEnter={this.handleSelectScreen}
                      onMouseLeave={this.handleUnselectScreen}
                    />
                  );
                }
              )}
          </Layer>
          {this.state.selectedScreen ? (
            <Popup
              coordinates={[
                this.state.selectedScreen.geopositionLongitude,
                this.state.selectedScreen.geopositionLatitude
              ]}
            >
              {this.state.selectedScreen.screenName}
              <br />
              {moment(this.state.selectedScreen.timestamp).fromNow()}
            </Popup>
          ) : null}
        </Mapbox>
      </React.Fragment>
    );
  }
}

export default observer(Map);
