import React from "react";
import { observer } from "mobx-react";
import { Box, Heading } from "grommet";

import SideBar from "./SideBar";
import LoadingIndicator from "../components/LoadingIndicator";
import ErrorSummary from "../components/Errors/ErrorSummary";
import ensureStore from "../store";

class Errors extends React.Component {
  constructor(props) {
    super(props);
    this.store = ensureStore();
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    setImmediate(() => this.store.errors.fetch());
  }

  renderErrors() {
    const { errors } = this.store;

    if (!errors) {
      return null;
    }

    return errors.items.map((error) => (
      <ErrorSummary key={error.id} error={error} />
    ));
  }

  render() {
    return (
      <React.Fragment>
        <SideBar
          history={this.props.history}
          location={this.props.location}
          onRefresh={this.handleRefresh}
        />
        <Box
          direction="column"
          background={{ color: "light-2" }}
          pad={{ horizontal: "xlarge", vertical: "medium" }}
        >
          <Box direction="row" pad={{ top: "medium", bottom: "large" }} alignSelf="end">
            <Heading
              level={1}
              size="large"
              style={{ fontWeight: "300", letterSpacing: "-0.033em" }}
            >
              Issues
            </Heading>
          </Box>
          <Box margin={{ bottom: "medium" }}>
            <LoadingIndicator
              isLoading={this.store.errors.isLoading}
              size={8}
              color="#333"
            />
          </Box>
          <Box gap="hair">{this.renderErrors()}</Box>
        </Box>
      </React.Fragment>
    );
  }
}

export default observer(Errors);
