import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import Moment from "react-moment";
import { Box, Heading, Text } from "grommet";

import Metric from "../Metric";
import MetricRow from "../MetricRow";
import ListRow from "../ListRow";
import StatusIndicator from "../StatusIndicator";

class CheckoutSummary extends React.Component {
  renderEmployee() {
    const { checkout } = this.props;
    const name = checkout.employee ? checkout.employee.name : null;

    return <Metric value={name} plain/>;
  }

  render() {
    const { checkout } = this.props;

    if (!checkout) {
      return null;
    }

    const { screen, status } = checkout;

    return (
      <ListRow link={`/customer/checkouts/${checkout.id}`}>
        <Box direction="row" align="center">
          <Box margin={{ right: "medium" }}>
            <StatusIndicator status={status} />
          </Box>
          <Box>
            <Heading level={2} size="medium">
              {screen ? screen.name : checkout.screenId}
            </Heading>
            <Text size="small">
              <Moment format="LLL">{checkout.createdAtUtc}</Moment>
            </Text>
          </Box>
        </Box>

        <MetricRow>
          {this.renderEmployee()}
          <Metric value={checkout.totalItemQuantity} plain/>
          <Metric value={checkout.totalShippedItemQuantity} plain/>
          <Metric value={checkout.totalPrice} unit={checkout.currencyCode} plain/>
        </MetricRow>
      </ListRow>
    );
  }
}

CheckoutSummary.propTypes = {
  checkout: PropTypes.object
};

export default observer(CheckoutSummary);
