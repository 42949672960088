import { types, getRoot, getEnv, flow } from "mobx-state-tree";

import mapToList from "../../lib/mapToList";
import sort from "../../lib/sort";

const CategoryListingDefinition = types.model("CategoryListingDefinition", {
  $type: types.string,
  categorySlug: types.string
});

const AggregateListingDefinition = types.model("AggregateListingDefinition", {
  $type: types.string,
  operator: types.enumeration(["or"]),
  definitions: types.array(CategoryListingDefinition)
});

const Image = types.model("Image", {
  url: types.string
});

const HeroImage = types.model("HeroImage", {
  defaultValue: Image
});

const TileImage = types.model("TileImage", {
  defaultValue: Image
});

const WebMenuItem = types.model("WebMenuItem", {
  $type: types.string,
  id: types.identifier,
  tileImage: TileImage,
  url: types.string,
  cssOverride: types.maybe(types.string)
});

const SubMenuItem = types.model("SubMenuItem", {
  $type: types.string,
  id: types.identifier,
  tileImage: TileImage,
  filters: types.array(types.string),
  definition: types.union({
    dispatcher: ({ $type }) => {
      switch ($type) {
        case "CategoryListingDefinition":
          return CategoryListingDefinition;
        case "AggregateListingDefinition":
          return AggregateListingDefinition;
        default:
          return undefined;
      }
    }
  })
});

const TopMenuItem = types.model("TopMenuItem", {
  $type: types.string,
  id: types.identifier,
  tileImage: TileImage,
  children: types.array(
    types.union({
      dispatcher: ({ $type }) => {
        switch ($type) {
          case "SubMenuItem":
            return SubMenuItem;
          case "WebMenuItem":
            return WebMenuItem;
          default:
            return undefined;
        }
      }
    })
  )
});

const Menu = types
  .model("Menu", {
    id: types.identifier,
    json: types.frozen(),
    heroImage: types.maybeNull(HeroImage),
    items: types.optional(
      types.array(
        types.union({
          dispatcher: ({ $type }) => {
            switch ($type) {
              case "TopMenuItem":
                return TopMenuItem;
              case "SubMenuItem":
                return SubMenuItem;
              case "WebMenuItem":
                return WebMenuItem;
              default:
                return undefined;
            }
          }
        })
      ),
      []
    )
  })
  .views(self => ({
    get screenCount() {
      const screens = getRoot(self).screens.sortedItems.filter(({ settings }) => {
        return settings.has("menu") && settings.get("menu").value === self.id;
      });

      return screens.length || 0;
    }
  }));

export default types
  .model("Menus", {
    items: types.optional(types.map(Menu), {}),
    sortKey: "screenCount"
  })
  .views(self => ({
    get sortedItems() {
      return sort(mapToList(self.items, true), self.sortKey, true);
    },
    get isLoading() {
      return getRoot(self).pendingActions.has('fetchMenus');
    }
  }))
  .actions(self => ({
    reset() {
      self.items = {};
    },
    /**
     * Fetches a particular menu by its ID
     */
    fetchById(id) {
      const { cancellationToken, cancel } = getEnv(self).connector.getCancellationTokenSource();

      const action = flow(function* fetchById() {
        const menu = yield getEnv(self).connector.fetchMenu(id, cancellationToken);
        self.items.set(id, { ...menu, json: menu });
      });

      return getRoot(self).runAction(`fetchMenuById-${id}`, action, cancel);
    },
    /**
     * Fetches a list of menus
     */
    fetch() {
      const { cancellationToken, cancel } = getEnv(self).connector.getCancellationTokenSource();

      const action = flow(function* fetch() {
        const menus = yield getEnv(self).connector.fetchMenus(cancellationToken);
        menus.forEach(menu => self.items.set(menu.id, { ...menu, json: menu }));
      });

      return getRoot(self).runAction("fetchMenus", action, cancel);
    }
  }));
