import React from "react";
import { observer } from "mobx-react";
import { Box, Heading, Text, Image, TextInput } from "grommet";
import { LinkNext } from "grommet-icons";

import ensureStore from "../store";

import Modal from "../components/Modal";
import Metric from "../components/Metric";
import MetricRow from "../components/MetricRow";
import ListHeader from "../components/ListHeader";
import SuggestionsInput from "../components/SuggestionsInput";
import CheckoutSummary from "../components/Checkouts/CheckoutSummary";
import Button from "../components/Buttons/Button";
import SideBar from "./SideBar";

class Checkout extends React.Component {
  constructor(props) {
    super(props);

    this.store = ensureStore();
    this.state = { checkoutId: null, modal: null };
    this.handleSearch = this.handleSearch.bind(this);
    this.handleUpdateEmployee = this.handleUpdateEmployee.bind(this);

    this.handleShowCancelOrderModal = this.handleShowCancelOrderModal.bind(
      this
    );

    this.handleDismissCancelOrderModal = this.handleDismissCancelOrderModal.bind(
      this
    );

    this.handleShowShipItemsModal = this.handleShowShipItemsModal.bind(this);

    this.handleDismissShipItemsModal = this.handleDismissShipItemsModal.bind(
      this
    );
  }

  componentDidMount() {
    this.parseUrl(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.parseUrl(nextProps);
  }

  parseUrl(props) {
    const { checkoutId } = props.match.params;

    this.setState({ checkoutId });
    this.store.checkouts.fetchById(checkoutId);
  }

  handleShowCancelOrderModal() {
    this.setState({ modal: "cancelOrder" });
  }

  handleDismissCancelOrderModal() {
    this.setState({ modal: null });
  }

  handleShowShipItemsModal() {
    this.setState({ modal: "shipItems" });
  }

  handleDismissShipItemsModal() {
    this.setState({ modal: null });
  }

  handleUpdateShippingList(checkout, articleNumber, quantity) {
    checkout.updateShippingList(articleNumber, quantity);
  }

  handleSearch(checkoutId) {
    this.props.history.push(`/customer/checkouts/${checkoutId}`);
  }

  handleUpdateEmployee(checkout, name) {
    checkout.updateEmployee(name);
  }

  handleCancelOrder(checkout) {
    checkout.cancelOrder();
  }

  handleShipItems(checkout) {
    checkout.shipItems();
  }

  renderBasket({ basket, currencyCode }) {
    const items = basket.items.map(
      ({ quantity, shippedQuantity, articleNumber, pricePerItem }) => {
        const sku = this.store.products.skus.get(articleNumber);

        const name = sku && sku.product ? sku.product.name.defaultValue : null;
        const image = sku && sku.product ? sku.product.previewImage : null;

        const imageUrl = image
          ? image.url
          : "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

        return (
          <Box
            key={articleNumber}
            direction="row"
            background="white"
            margin={{ bottom: "hair" }}
            justify="between"
            align="center"
          >
            <Box direction="row">
              <Box height="xsmall" width="xsmall" background="light-1">
                <Image src={imageUrl} fit="contain" />
              </Box>
              <Box pad="medium" gap="xxsmall">
                <Heading level={3}>{name || ""}</Heading>
                <Heading level={4}>{articleNumber}</Heading>
              </Box>
            </Box>
            <MetricRow>
              <Metric value={quantity} plain />
              <Metric value={shippedQuantity} plain />
              <Metric value={pricePerItem} unit={currencyCode} plain />
            </MetricRow>
          </Box>
        );
      }
    );

    return (
      <Box>
        <ListHeader
          metricHeaders={[
            { label: "Ordered Quantity", sortKey: "orderedQuantity", sortDescending: true },
            { label: "Shipped Quantity", sortKey: "shippedQuantity", sortDescending: true },
            { label: "Item Price", sortKey: "pricePerItem", sortDescending: true }
          ]}
        />
        {items}
      </Box>
    );
  }

  renderShippingForm(checkout) {
    const { basket, currencyCode, shippingList } = checkout;

    return basket.items.map(({ articleNumber, pricePerItem }) => {
      const sku = this.store.products.skus.get(articleNumber);
      const shippingQuantity = shippingList.get(articleNumber);

      const name = sku && sku.product ? sku.product.name.defaultValue : null;
      const image = sku && sku.product ? sku.product.previewImage : null;

      const imageUrl = image
        ? image.url
        : "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

      return (
        <Box
          key={articleNumber}
          direction="row"
          background="light-1"
          margin={{ bottom: "hair" }}
          justify="between"
          align="center"
        >
          <Box direction="row">
            <Box height="xsmall" width="xsmall" background="light-1">
              <Image src={imageUrl} fit="contain" />
            </Box>
            <Box pad="medium" gap="xxsmall">
              <Heading level={3}>{name || ""}</Heading>
              <Heading level={4}>
                {articleNumber}, {pricePerItem} {currencyCode}
              </Heading>
            </Box>
          </Box>
          <Box
            width="xsmall"
            background="white"
            direction="row"
            margin={{ right: "small" }}
          >
            <TextInput
              alignSelf="center"
              value={`${shippingQuantity}`}
              onChange={e => {
                const quantity = e.target.value
                  ? parseInt(e.target.value, 10)
                  : 0;
                this.handleUpdateShippingList(
                  checkout,
                  articleNumber,
                  quantity
                );
              }}
            />
          </Box>
        </Box>
      );
    });
  }

  renderError({ hasError, errorMessage }) {
    if (!hasError) {
      return null;
    }

    return (
      <Box background={{ color: "white" }} pad="medium">
        <Heading level={2}>Error:</Heading>
        {errorMessage}
      </Box>
    );
  }

  render() {
    const checkout = this.store.checkouts.items.get(this.state.checkoutId);

    if (!checkout) {
      return null;
    }

    const {
      id,
      externalOrderId,
      deliveryCountryCode,
      paymentStatus,
      orderStatus,
      handlerCode,
      canShipItems,
      canCancelOrder
    } = checkout;

    return (
      <React.Fragment>
        <SideBar history={this.props.history} location={this.props.location} />
        <Box
          direction="column"
          background={{ color: "light-2" }}
          pad="xlarge"
          gap="small"
        >
          <Box
            pad={{ top: "medium", bottom: "large" }}
            gap="small"
            alignSelf="end"
            align="end"
          >
            <Heading
              level={1}
              size="large"
              style={{ fontWeight: "400", letterSpacing: "-0.033em" }}
            >
              Checkout
            </Heading>
            <Text>{id}</Text>
          </Box>
          <CheckoutSummary checkout={checkout} />

          <Box
            background="white"
            pad="medium"
            gap="small"
            direction="row"
            justify="between"
            align="center"
          >
            <Heading level={2}>Staff</Heading>
            <Box width="medium">
              <SuggestionsInput
                placeholder="Enter Staff"
                value={checkout.employee ? checkout.employee.name : null}
                suggestions={this.store.checkouts.employeeNames}
                onChange={(name) => this.handleUpdateEmployee(checkout, name)}
              />
            </Box>
          </Box>

          <Box background="white" pad="medium" gap="small">
            <Heading level={2}>
              {externalOrderId ? `Order ${externalOrderId}` : "Order"}
            </Heading>
            <Box direction="row" justify="between">
              <Box direction="column">
                <Text>
                  <strong>Country:</strong> {deliveryCountryCode}
                </Text>
                <Text>
                  <strong>Method:</strong> {handlerCode}
                </Text>
                <Text>
                  <strong>Payment:</strong> {paymentStatus}
                </Text>
                <Text>
                  <strong>Status:</strong> {orderStatus}
                </Text>
              </Box>

              <Box direction="row" align="center" alignSelf="end" gap="small">
                {canCancelOrder ? (
                  <Button
                    onPress={this.handleShowCancelOrderModal}
                    label="Cancel Order"
                  />
                ) : null}
                {canShipItems ? (
                  <Button
                    onPress={this.handleShowShipItemsModal}
                    icon={<LinkNext />}
                    label="Ship Items"
                    isPrimary
                    isReverse
                  />
                ) : null}
              </Box>
            </Box>
          </Box>
          {this.renderError(checkout)}
          {this.renderBasket(checkout)}
        </Box>
        <Modal
          isActive={this.state.modal === "cancelOrder"}
          canConfirm={true}
          onConfirm={() => this.handleCancelOrder(checkout)}
          onDismiss={this.handleDismissCancelOrderModal}
          dismissLabel="No"
          confirmLabel="Yes, cancel order"
          heading="Cancel order"
        >
          <Text margin={{ bottom: "small" }}>
            Do you really want to cancel this order?
          </Text>
        </Modal>
        <Modal
          isActive={this.state.modal === "shipItems"}
          canConfirm={true}
          onConfirm={() => this.handleShipItems(checkout)}
          onDismiss={this.handleDismissShipItemsModal}
          confirmLabel="Ship items"
          heading="Ship items"
          width="large"
          pad="none"
        >
          {this.renderShippingForm(checkout)}
        </Modal>
      </React.Fragment>
    );
  }
}

export default observer(Checkout);
