import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Heading, Box, Text } from "grommet";

import ListRow from "../ListRow";

class CustomerSummary extends React.Component {
  render() {
    const { customer } = this.props;

    if (!customer) {
      return null;
    }

    const { id, name } = customer;

    return (
      <ListRow link={`/admin/customers/${id}`}>
        <Box>
          <Heading level={1} size="small">
            {name}
          </Heading>
          <Text size="small">
            {id}
          </Text>         
        </Box>
      </ListRow>
    );
  }
}

CustomerSummary.propTypes = {
  customer: PropTypes.object
};

export default observer(CustomerSummary);
