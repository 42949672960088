import { types, getEnv, getRoot, flow } from 'mobx-state-tree';

import User from './user';

export default types
	.model('Identity', {
		error: types.maybeNull(types.string),
		user: types.maybeNull(User)
	})
	.views((self) => ({
		get customerId() {
			return self.user ? self.user.customerId : null;
		},

		get isAdmin() {
			return self.user && self.user.isAdmin;
		},

		get isSignedIn() {
			return self.user !== null;
		},

		get isSigningIn() {
			return getRoot(self).pendingActions.has('signIn');
		}
	}))
	.actions((self) => ({
		reset() {
			self.error = null;
			self.user = null;
		},

		setUser(user) {
			self.user = user;
		},

		signOut() {
			return getEnv(self).connector.auth.signOut();
		},

		signIn(email, password) {
			const action = flow(function* signIn() {
				try {
					yield getEnv(self).connector.auth.signIn(email, password);
					self.error = null;
				} catch (err) {
					self.error = err.message;
				}
			});

			return getRoot(self).runAction('signIn', action);
		}
	}));
