import { types, getRoot, getEnv, flow } from "mobx-state-tree";

import Checkout from "./checkout";
import mapToList from "../../lib/mapToList";
import sort from "../../lib/sort";

export default types
  .model("Checkouts", {
    sortKey: "createdAtUtc",
    items: types.optional(types.map(Checkout), {})
  })
  .views(self => ({
    get filteredItems() {
      const items = mapToList(self.items, true);

      return getRoot(self).showDemoScreens
        ? items
        : items.filter(({ screen }) => !screen || !screen.isDemo);
    },
    get sortedItems() {
      return sort(self.filteredItems, self.sortKey, true);
    },
    get employeeNames() {
      const set = self.filteredItems.reduce((result, checkout) => {
        if (checkout.employee && checkout.employee.name) {
          result.add(checkout.employee.name);
        }

        return result;
      }, new Set());

      return [...set];
    },
    get isLoading() {
      return (
        getRoot(self).pendingActions.has("fetchCheckouts") ||
        getRoot(self).pendingActions.has("fetchScreens")
      );
    }
  }))
  .actions(self => ({
    reset() {
      self.items = {};
    },

    /**
     * Fetches a particular checkout by its ID
     */
    fetchById(id, fetchReferencedEntities = true) {
      const { cancellationToken, cancel } = getEnv(
        self
      ).connector.getCancellationTokenSource();

      const action = flow(function* fetchById() {
        const checkout = yield getEnv(self).connector.fetchCheckout(
          id,
          cancellationToken
        );

        if (!checkout) {
          return;
        }

        const shippingList = checkout.basket.items.reduce((result, item) => {
          result[item.articleNumber] = item.quantity;
          return result;
        }, {});

        self.items.set(id, { ...checkout, shippingList });

        if (fetchReferencedEntities) {
          // TODO: Share cancellationToken
          getRoot(self).screens.fetchById(checkout.screenId);

          // TODO: Change once we can fetch list of articles
          for (const item of checkout.basket.items) {
            getRoot(self).products.fetchByArticleNumber(item.articleNumber);
          }
        }
      });

      return getRoot(self).runAction(`fetchCheckoutById-${id}`, action, cancel);
    },
    /**
     * Fetches a list of checkouts in the selected date range
     */
    fetch() {
      const { cancellationToken, cancel } = getEnv(
        self
      ).connector.getCancellationTokenSource();

      const action = flow(function* fetch() {
        const checkouts = yield getEnv(self).connector.fetchCheckouts(
          null,
          getRoot(self).fromDate,
          getRoot(self).toDate,
          true,
          cancellationToken
        );

        //self.items = {};
        checkouts.forEach(checkout => self.items.set(checkout.id, checkout));
      });

      return getRoot(self).runAction("fetchCheckouts", action, cancel);
    },
    /**
     * Returns a list of checkouts in the selected date range
     */
    async fetchExport() {
      let [checkouts, screens] = await Promise.all([
        getEnv(self).connector.fetchCheckouts(
          null,
          getRoot(self).fromDate,
          getRoot(self).toDate,
          false
        ),
        getEnv(self).connector.fetchScreens()
      ]);

      const screenMap = new Map();

      for (const { id, name, settings } of screens) {
        const storeId = settings.storeId ? settings.storeId.value : null;

        screenMap.set(id, {
          storeId,
          screenName: name
        });
      }

      checkouts = checkouts.map(checkout => {
        const screen = screenMap.get(checkout.screenId);
        return { ...checkout, ...screen };
      });

      return checkouts;
    }
  }));
