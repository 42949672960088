import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Box, Text, CheckBox } from "grommet";

class GroupedSelectorItem extends React.Component {
  constructor(props) {
    super(props);

    this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
  }

  handleSelectionChanged(e) {
    const { item } = this.props;

    if (e.target.checked) {
      this.props.onSelect(item);
    } else {
      this.props.onUnselect(item);
    }
  }

  render() {
    const {
      item,
      itemIdKey,
      itemLabelKey,
      itemFallbackLabelKey,
      itemContentKey,
      selectedItems
    } = this.props;

    if (!item) {
      return null;
    }

    const label = item[itemLabelKey];
    const fallbackLabel = item[itemFallbackLabelKey];
    const isSelected = selectedItems.has(item[itemIdKey]);

    return (
      <Box
        key={item[itemIdKey]}
        background="light-1"
        pad={{ left: "large", right: "xlarge", vertical: "small" }}
        direction="row"
        justify="between"
        align="center"
      >
        <Box direction="row" gap="small" align="center">
          <CheckBox
            checked={isSelected}
            onChange={this.handleSelectionChanged}
          />
          <Text>{label || fallbackLabel}</Text>
        </Box>
        <Text>{item[itemContentKey]}</Text>
      </Box>
    );
  }
}

GroupedSelectorItem.propTypes = {
  item: PropTypes.object.isRequired,
  itemIdKey: PropTypes.string,
  itemLabelKey: PropTypes.string,
  itemContentKey: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onUnselect: PropTypes.func.isRequired,
  selectedItems: PropTypes.object
};

export default observer(GroupedSelectorItem);
