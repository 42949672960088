import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { Box } from "grommet";

import "./SideBarButton.css";

class SideBarButton extends React.Component {
  render() {
    const { link, isActive, children } = this.props;
    const classes = ["sidebar-button"];

    if (isActive) {
      classes.push("sidebar-button-active");
    }

    return (
      <Link to={link} className={classes.join(" ")}>
        <Box
          align="center"
          justify="center"
          height="60px"
          width="56px"
          border={{ side: "bottom", width: "1px", color: "light-4" }}
        >
          {children}
        </Box>
      </Link>
    );
  }
}

SideBarButton.propTypes = {
  link: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  children: PropTypes.any
};

export default observer(SideBarButton);
