import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Storage } from "grommet-icons";

import { Box, Heading, Text } from "grommet";

import Meter from "../Meter";
import ListRow from "../ListRow";
import Metric from "../Metric";
import MetricRow from "../MetricRow";
import StatusIndicator from "../StatusIndicator";

class HeartbeatSummary extends React.Component {
  render() {
    const { heartbeat } = this.props;

    if (!heartbeat) {
      return null;
    }

    const {
      id,
      screenName,
      hasWarning,
      isOnline,
      appVersion,
      displaySize,
      countryCode,
      networkRttMs,
      hasActiveSession,
      lastPingMinutes,
      productsNotProcessedCount,
      productsReadyEnabledCount,
      roundedStoragePercentageUsed,
    } = heartbeat;

    let status = "unknown";

    if (isOnline) {
      status = "ok";
    }

    if (hasWarning) {
      status = "warning";
    }

    return (
      <ListRow link={`/admin/monitoring/screens/${id}`}>
        <Box direction="row" align="center">
          <Box margin={{ right: "medium" }}>
            <StatusIndicator status={status} hasActivity={hasActiveSession} />
          </Box>
          <Box>
            <Heading level={2} size="medium">
              {screenName}
            </Heading>
            <Box direction="row" gap="xsmall" margin={{ top: "xsmall" }}>
              <Box
                background="light-3"
                pad={{ horizontal: "small" }}
                round="10px"
              >
                <Text size="small">
                  {appVersion ? `Vendo ${appVersion}` : null}
                </Text>
              </Box>
              <Box
                background="light-3"
                pad={{ horizontal: "small" }}
                round="10px"
              >
                <Text size="small">
                  {displaySize ? `${Math.round(displaySize)}"` : null}
                </Text>
              </Box>
              {countryCode ? (
                <Box
                  background="light-3"
                  pad={{ horizontal: "small" }}
                  round="10px"
                >
                  <Text size="small">{countryCode}</Text>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
        <MetricRow>
          <Metric value={lastPingMinutes} unit="min" plain />
          <Metric value={productsReadyEnabledCount} plain />
          <Metric value={productsNotProcessedCount} plain />
          <Metric value={networkRttMs} unit="ms" plain />
          <Meter
            value={roundedStoragePercentageUsed}
            icon={<Storage size="15px" />}
            label="Disk"
            description="Disk usage in percent"
            maxThreshold={85}
          />
        </MetricRow>
      </ListRow>
    );
  }
}

HeartbeatSummary.propTypes = {
  heartbeat: PropTypes.object,
};

export default observer(HeartbeatSummary);
