import React from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import { Info } from "grommet-icons";
import { CheckBox, TextInput, Text, Box, DropButton, Heading } from "grommet";

import OptionSelector from "../OptionSelector";

class Setting extends React.Component {
  constructor(props) {
    super(props);

    this.handleOptionChanged = this.handleOptionChanged.bind(this);
    this.handleCheckboxChanged = this.handleCheckboxChanged.bind(this);
    this.handleTextChanged = this.handleTextChanged.bind(this);
    this.handleNumberChanged = this.handleNumberChanged.bind(this);
  }

  handleOptionChanged(values) {
    const { setting, onSettingChanged } = this.props;
    onSettingChanged(setting.key, values);
  }

  handleCheckboxChanged(e) {
    const { setting, onSettingChanged } = this.props;
    onSettingChanged(setting.key, e.target.checked);
  }

  handleTextChanged(e) {
    const { setting, onSettingChanged } = this.props;
    const sanitizedText = e.target.value.trim();

    onSettingChanged(setting.key, sanitizedText);
  }

  handleNumberChanged(e) {
    const { setting, onSettingChanged } = this.props;
    const int = parseInt(e.target.value, 10);
    onSettingChanged(setting.key, int);
  }

  renderDescription() {
    if (!this.props.description) {
      return null;
    }

    return (
      <Box
        background="light-2"
        alignSelf="center"
        align="center"
        justify="center"
        width="28px"
        height="28px"
        round="16px"
        style={{ overflow: "hidden" }}
      >
        <DropButton
          fill
          align="center"
          justify="center"
          dropAlign={{ top: "bottom", center: "center" }}
          dropContent={
            <Box
              pad="medium"
              width="medium"
              background="light-1"
              gap="medium"
              height="medium"
            >
              <Heading level={3}>{this.props.label}</Heading>
              {this.props.description}
            </Box>
          }
          elevation="large"
          background="light-2"
          plain
        >
          <Box
            margin={{ top: "1px" }}
            alignSelf="center"
            align="center"
            justify="center"
          >
            <Info size="15px" />
          </Box>
        </DropButton>
      </Box>
    );
  }

  render() {
    const { setting, label, unit } = this.props;

    if (!setting || !setting.constraints) {
      return null;
    }

    const { nativeOptions, allowMultiple, type } = setting.constraints;

    if (nativeOptions) {
      const selectedValues = allowMultiple
        ? setting.nativeValue
        : [setting.nativeValue];

      return (
        <React.Fragment>
          <Box direction="row" gap="small" fill>
            <Text alignSelf="center">{label}</Text>

            {this.renderDescription()}
          </Box>
          <OptionSelector
            value={selectedValues}
            options={nativeOptions}
            onChange={this.handleOptionChanged}
            //showEmptyOption={!allowMultiple}
            isMulti={allowMultiple}
            fill
          />
        </React.Fragment>
      );
    } else if (setting.nativeValue === true || setting.nativeValue === false) {
      // TODO: Ideally we would get type === "boolean" from API.
      return (
        <React.Fragment>
          <Text alignSelf="center">{label}</Text>
          <Box direction="row" gap="small" fill>
            <CheckBox
              checked={setting.nativeValue}
              onChange={this.handleCheckboxChanged}
              reverse
              toggle
            />
            {this.renderDescription()}
          </Box>
        </React.Fragment>
      );
    } else if (type === "number") {
      return (
        <React.Fragment>
          <Text alignSelf="center">{label}</Text>
          <Box direction="row" gap="small" fill>
            <Box direction="row" align="center" gap="small">
              <TextInput
                value={`${setting.nativeValue}`}
                onChange={this.handleNumberChanged}
                placeholder="none"
              />
              <Text>{unit}</Text>
            </Box>
            {this.renderDescription()}
          </Box>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Box direction="row" gap="small" fill>
            <Text alignSelf="center">{label}</Text>
            {this.renderDescription()}
          </Box>
          <TextInput
            value={`${setting.nativeValue}`}
            onChange={this.handleTextChanged}
            placeholder="none"
          />
        </React.Fragment>
      );
    }
  }
}

Setting.propTypes = {
  defaultValue: PropTypes.any,
  setting: PropTypes.object.isRequired,
  onSettingChanged: PropTypes.func.isRequired,
  label: PropTypes.string,
  allOrDefault: PropTypes.bool,
  className: PropTypes.string,
  unit: PropTypes.string
};

export default observer(Setting);
