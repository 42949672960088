import React from "react";
import { observer } from "mobx-react";
import { Box, Heading } from "grommet";

import ensureStore from "../store";

import SideBar from "./SideBar";
import DemoToggle from "./DemoToggle";
import ListHeader from "../components/ListHeader";
import ScreenSummary from "../components/Screens/ScreenSummary";
import SearchInput from "../components/SearchInput";

class Screens extends React.Component {
  constructor(props) {
    super(props);
    this.store = ensureStore();

    this.handleDemoToggleChange = this.handleDemoToggleChange.bind(this);
    this.handleSelectMetric = this.handleSelectMetric.bind(this);
    this.handleSearch = this.handleSearch.bind(this);

    this.refreshIntervalHandle = null;
  }

  componentDidMount() {
    this.fetchData();

    this.clearRefreshInterval();

    this.refreshIntervalHandle = setInterval(
      () => this.store.screens.fetchLatestHeartbeats(),
      60000
    );
  }

  componentWillReceiveProps(nextProps) {
    this.fetchData();
  }

  componentWillUnmount() {
    this.clearRefreshInterval();
  }

  clearRefreshInterval() {
    if (this.refreshIntervalHandle) {
      clearInterval(this.refreshIntervalHandle);
    }
  }

  fetchData() {
    setImmediate(() => {
      this.store.screens.fetch();
      this.store.screens.fetchLatestHeartbeats();
    });
  }

  handleDemoToggleChange() {
    this.fetchData();
  }

  handleSelectMetric(sortKey, sortDescending) {
    this.store.screens.setSortKey(sortKey, sortDescending);
  }

  handleSearch(screenId) {
    this.props.history.push(`/customer/screens/${screenId}`);
  }

  renderScreens() {
    return this.store.screens.sortedItems.map(screen => {
      return <ScreenSummary key={screen.id} screen={screen} />;
    });
  }

  render() {
    const {
      totalCount,
      onlineCount,
      isLoading,
      sortDescending,
      sortKey
    } = this.store.screens;

    return (
      <React.Fragment>
        <SideBar history={this.props.history} location={this.props.location} />
        <Box
          direction="column"
          background={{ color: "light-2" }}
          pad={{ horizontal: "xlarge", vertical: "medium" }}
        >
          <SearchInput
            placeholder="Go to screen Id..."
            onSubmit={this.handleSearch}
          />
          <Box
            pad={{ top: "medium", bottom: "xlarge" }}
            align="end"
            alignSelf="end"
          >
            <Box direction="row" align="end">
              <Heading
                level={1}
                size="large"
                style={{ fontWeight: "600", letterSpacing: "-0.033em" }}
              >
                {onlineCount}
              </Heading>
              <Heading
                level={1}
                size="large"
                style={{ fontWeight: "300", letterSpacing: "-0.033em" }}
              >{`/${totalCount} online`}</Heading>
            </Box>
            <DemoToggle
              alignSelf="end"
              onChange={this.handleDemoToggleChange}
            />
          </Box>
          <ListHeader
            isLoading={isLoading}
            onSelectMetric={this.handleSelectMetric}
            sortDescending={sortDescending}
            selectedMetric={sortKey}
            metricHeaders={[
              {
                label: "Last Ping",
                sortKey: "lastPingMinutes",
                sortDescending: true
              },
              {
                label: "Network Latency",
                sortKey: "networkRttMs",
                sortDescending: true
              },
              {
                label: "Utilized Storage",
                sortKey: "roundedStoragePercentageUsed",
                sortDescending: true
              }
            ]}
          />
          <Box gap="hair">{this.renderScreens()}</Box>
        </Box>
      </React.Fragment>
    );
  }
}

export default observer(Screens);
