import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import ReactBarcode from "react-barcode";

class Barcode extends React.Component {
  render() {
    const { value } = this.props;

    if (!value) {
      return null;
    }

    return <ReactBarcode value={value} margin={0} />;
  }
}

Barcode.propTypes = {
  value: PropTypes.string
};

export default observer(Barcode);
