import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Form, TextInput, Box } from "grommet";
import { Search } from "grommet-icons";

class SearchInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = { searchText: null };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    if (e.target.value) {
      this.setState({ searchText: e.target.value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    if (!this.state.searchText) {
      return;
    }

    this.props.onSubmit(this.state.searchText);
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Box direction="row" fill={true} align="center" gap="xxsmall">
          <Search />
          <TextInput
            type="search"
            style={{ fontFamily: "din-2014"}}
            placeholder={this.props.placeholder || "Search..."}
            value={this.state.searchTect}
            onChange={this.handleChange}
            size="medium"
            plain
          />
        </Box>
      </Form>
    );
  }
}

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  onSubmit: PropTypes.func.isRequired
};

export default observer(SearchInput);
