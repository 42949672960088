import React from "react";
import { observer } from "mobx-react";
import { Box, Heading } from "grommet";

import mapToList from "../lib/mapToList";
import ensureStore from "../store";

import SideBar from "./SideBar";
import DemoToggle from "./DemoToggle";
import ListHeader from "../components/ListHeader";
import SearchInput from "../components/SearchInput";
import ChartCard from "../components/Charts/ChartCard";
import PieChart from "../components/Charts/PieChart";
import HeartbeatSummary from "../components/Monitoring/HeartbeatSummary";

class Monitoring extends React.Component {
  constructor(props) {
    super(props);
    this.store = ensureStore();

    this.handleDemoToggleChange = this.handleDemoToggleChange.bind(this);
    this.handleSelectMetric = this.handleSelectMetric.bind(this);
    this.handleSearch = this.handleSearch.bind(this);

    this.refreshIntervalHandle = null;
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    this.fetchData();
  }

  fetchData() {
    setImmediate(() => {
      this.store.screens.fetchLatestHeartbeats();
      this.store.stats.fetchHeartbeatStats();
    });
  }

  handleDemoToggleChange() {
    this.fetchData();
  }

  handleSelectMetric(sortKey, sortDescending) {
    this.store.screens.setSortKey(sortKey, sortDescending);
  }

  handleSearch(screenId) {
    this.props.history.push(`/admin/monitoring/screens/${screenId}`);
  }

  render() {
    const {
      sortKey,
      sortDescending,
      totalHeartbeatCount,
      onlineHeartbeatCount
    } = this.store.screens;

    const {
      appVersion,
      windowsVersion,
      networkType,
      displaySize,
      countryCode
    } = this.store.stats.heartbeats;

    const dataOptions = [
      {
        key: "app-version",
        label: "App Version",
        data: mapToList(appVersion)
      },
      {
        key: "windows-version",
        label: "Windows Version",
        data: mapToList(windowsVersion)
      },
      {
        key: "network-type",
        label: "Network Type",
        data: mapToList(networkType)
      },
      {
        key: "display-size",
        label: "Display Size",
        data: mapToList(displaySize)
      },
      {
        key: "country-code",
        label: "Country Code",
        data: mapToList(countryCode)
      }
    ];

    return (
      <React.Fragment>
        <SideBar history={this.props.history} location={this.props.location} />
        <Box
          direction="column"
          background={{ color: "light-2" }}
          pad={{ horizontal: "xlarge", vertical: "medium" }}
        >
          <SearchInput
            placeholder="Go to screen Id..."
            onSubmit={this.handleSearch}
          />
          <Box
            pad={{ top: "medium", bottom: "xlarge" }}
            align="end"
            alignSelf="end"
          >
            <Box direction="row" align="end">
              <Heading
                level={1}
                size="large"
                style={{ fontWeight: "600", letterSpacing: "-0.033em" }}
              >
                {onlineHeartbeatCount}
              </Heading>
              <Heading
                level={1}
                size="large"
                style={{ fontWeight: "300", letterSpacing: "-0.033em" }}
              >{`/${totalHeartbeatCount} online`}</Heading>
            </Box>
            <DemoToggle
              alignSelf="end"
              onChange={this.handleDemoToggleChange}
            />
          </Box>
          <Box direction="row" gap="small">
            <ChartCard
              isLoading={this.store.stats.isLoading}
              dataOptions={dataOptions}
              defaultOption="app-version"
              labelName="key"
            >
              <PieChart id="analytics-pie-chart-1" />
            </ChartCard>
            <ChartCard
              isLoading={this.store.stats.isLoading}
              dataOptions={dataOptions}
              defaultOption="network-type"
              labelName="key"
            >
              <PieChart id="analytics-pie-chart-2" />
            </ChartCard>
          </Box>
          <ListHeader
            isLoading={this.store.screens.isLoading}
            onSelectMetric={this.handleSelectMetric}
            sortDescending={sortDescending}
            selectedMetric={sortKey}
            metricHeaders={[
              {
                label: "Last Ping",
                sortKey: "lastPingMinutes",
                sortDescending: true
              },
              {
                label: "Enabled Products",
                sortKey: "productsReadyEnabledCount",
                sortDescending: true
              },
              {
                label: "Unprocessed Products",
                sortKey: "productsNotProcessedCount",
                sortDescending: true
              },
              {
                label: "Network Latency",
                sortKey: "networkRttMs",
                sortDescending: true
              },
              {
                label: "Utilized Storage",
                sortKey: "roundedStoragePercentageUsed",
                sortDescending: true
              }
            ]}
          />
          <Box gap="hair">
            {this.store.screens.sortedHeartbeats.map(heartbeat => {
              return (
                <HeartbeatSummary key={heartbeat.id} heartbeat={heartbeat} />
              );
            })}
          </Box>
        </Box>
      </React.Fragment>
    );
  }
}

export default observer(Monitoring);
