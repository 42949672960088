import React from "react";
import { observer } from "mobx-react";
import { Box, Heading, Text } from "grommet";
import { Link } from "react-router-dom";

import ensureStore from "../store";

import SideBar from "./SideBar";
//import Timeline from '../components/Timeline';
import SessionSummary from "../components/Sessions/SessionSummary";

class Session extends React.Component {
  constructor(props) {
    super(props);

    // TODO: Get add screenId to path
    this.state = { sessionId: null };
    this.store = ensureStore();
  }

  componentDidMount() {
    this.parseUrl(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.parseUrl(nextProps);
  }

  parseUrl(props) {
    const { sessionId } = props.match.params;

    this.setState({ sessionId });
    this.store.sessions.fetchById(sessionId);
  }

  /*
	renderEvents(events) {
		if (!events || !events.length) {
			return null;
		}

		const options = {
			width: '100%',
			zoomKey: 'altKey',
			type: 'box'
		};

		const groups = events.reduce((prev, cur) => {
			if (!prev.find((g) => g.id === cur.view)) {
				prev.push({ id: cur.view });
			}

			return prev;
		}, []);

		return (
			<Timeline
				options={options}
				groups={groups}
				items={events.filter((event) => event.name !== 'Exception').map((event) => {
					return { start: event.timestamp, content: event.name, group: event.view };
				})}
			/>
		);
  }
  */

  renderTerms(session, key, label) {
    if (!session) {
      return null;
    }

    const terms = session[key];

    if (!terms || !terms.length) {
      return null;
    }

    const items = terms.map((term, i) => <Text key={i}>{term}</Text>);

    return (
      <Box>
        <Heading level={3}>{label}</Heading>
        <Box margin={{ top: "medium" }}>{items}</Box>
      </Box>
    );
  }

  renderCheckoutLink(session) {
    if (!session || !session.checkoutId) {
      return null;
    }

    return (
      <Box background="white" pad="medium">
        <Link to={`/customer/checkouts/${session.checkoutId}`}>
          <Heading level={3}>Checkout</Heading>
          <Text>{session.checkoutId}</Text>
        </Link>
      </Box>
    );
  }

  renderSession(session) {
    if (!session) {
      return null;
    }

    return (
      <Box background="white" pad="medium" gap="medium">
        <Box direction="row" gap="large">
          {this.renderTerms(session, "views", "Views")}
          {this.renderTerms(session, "selectedMenuItems", "Menu Items")}
          {this.renderTerms(session, "selectedItems", "Products")}
          {this.renderTerms(session, "selectedFilters", "Filters")}
          {this.renderTerms(session, "searchTerms", "Search Terms")}
        </Box>
        <Box direction="row">
          {this.renderTerms(session, "basketArticleNumbers", "Basket")}
        </Box>
      </Box>
    );
  }

  render() {
    const session = this.store.sessions.items.get(this.state.sessionId);

    return (
      <React.Fragment>
        <SideBar history={this.props.history} location={this.props.location} />
        <Box
          direction="column"
          background={{ color: "light-2" }}
          pad={{ horizontal: "xlarge", vertical: "medium" }}
          gap="small"
        >
          <Box
            pad={{ top: "medium", bottom: "large" }}
            alignSelf="end"
            align="end"
          >
            <Heading
              level={1}
              size="large"
              style={{ fontWeight: "300", letterSpacing: "-0.033em" }}
            >
              Session
            </Heading>
            <Text>{session ? session.id : null}</Text>
          </Box>
          <SessionSummary session={session} />
          {this.renderSession(session)}
          {this.renderCheckoutLink(session)}
        </Box>
      </React.Fragment>
    );
  }
}

export default observer(Session);
