import React from "react";
import { observer } from "mobx-react";
import { Box, Heading, Layer, Text, FormField, TextInput } from "grommet";
import { Add } from "grommet-icons";

import ensureStore from "../store";

import SideBar from "./SideBar";
import Modal from "../components/Modal";
import ListRow from "../components/ListRow";
import Button from "../components/Buttons/Button";

class Customer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      userEmail: "",
      customerId: null,
      userId: null,
      dialog: null,
      oldPassword: "",
      newPassword: "",
    };

    this.store = ensureStore();

    this.handleCreateUser = this.handleCreateUser.bind(this);
    this.handleUserEmailChanged = this.handleUserEmailChanged.bind(this);
    this.handleChangeUserPassword = this.handleChangeUserPassword.bind(this);
    this.handleDismissModal = this.handleDismissModal.bind(this);
    this.handleShowAddUserModal = this.handleShowAddUserModal.bind(this);
    this.handleShowRemoveUserModal = this.handleShowRemoveUserModal.bind(this);
    this.handleShowPasswordModal = this.handleShowPasswordModal.bind(this);
  }

  componentDidMount() {
    this.parseUrl(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.parseUrl(nextProps);
  }

  parseUrl(props) {
    const { customerId } = props.match.params;

    this.setState({ customerId });
    this.store.customers.fetchById(customerId);
    this.store.customers.fetchUsers(customerId);
  }

  handleCreateUser() {
    return this.store.customers.createUser(
      this.state.customerId,
      this.state.userEmail,
      true
    );
  }

  handleCancelDialog() {
    this.setState({ dialog: null });
  }

  handleRemoveUser() {
    return this.store.customers.removeUser(
      this.state.customerId,
      this.state.userId,
      true
    );
  }

  handleChangeUserPassword() {
    const user = this.store.customers.customerUsers
      .get(this.state.customerId)
      .find(({ id }) => id === this.state.userId);

    return user.changePassword(
      this.state.oldPassword,
      this.state.newPassword,
      true
    );
  }

  handleNewPasswordChanged(e) {
    this.setState({ newPassword: e.target.value });
  }

  handleOldPasswordChanged(e) {
    this.setState({ oldPassword: e.target.value });
  }

  handleUserEmailChanged(e) {
    this.setState({ userEmail: e.target.value });
  }

  handleShowAddUserModal() {
    this.setState({ modal: "createUser" });
  }

  handleShowPasswordModal(user) {
    this.setState({
      modal: "changeUserPassword",
      userId: user.id,
      oldPassword: user.password,
    });
  }

  handleShowRemoveUserModal(user) {
    this.setState({ modal: "removeUser", userId: user.id });
  }

  handleDismissModal() {
    this.setState({
      modal: null,
      userEmail: "",
      oldPassword: "",
      newPassword: "",
    });
  }

  renderDialog() {
    if (!this.state.customerId) {
      return null;
    }

    const { oldPassword, newPassword, userEmail, modal } = this.state;

    return (
      <React.Fragment>
        <Modal
          isActive={modal === "createUser"}
          onConfirm={this.handleCreateUser}
          onDismiss={this.handleDismissModal}
          canConfirm={userEmail.length > 0}
          confirmLabel="Create user"
          heading="New user"
        >
          <FormField>
            <TextInput
              type="email"
              value={userEmail}
              onChange={this.handleUserEmailChanged}
              placeholder="Email"
            />
          </FormField>
        </Modal>
        <Modal
          isActive={modal === "removeUser"}
          canConfirm={true}
          onConfirm={this.handleRemoveUser.bind(this)}
          onDismiss={this.handleDismissModal}
          confirmLabel="Remove user"
          heading="Remove user"
        >
          <Text margin={{ bottom: "small" }}>
            Do you really want to remove this user?
          </Text>
        </Modal>
        <Modal
          isActive={modal === "changeUserPassword"}
          canConfirm={!!oldPassword && !!newPassword}
          onConfirm={this.handleChangeUserPassword}
          onDismiss={this.handleDismissModal}
          confirmLabel="Reset password"
          heading="Change password"
        >
          <FormField>
            <TextInput
              type="password"
              value={oldPassword}
              onChange={this.handleOldPasswordChanged.bind(this)}
              placeholder="Old Password"
            />
          </FormField>
          <FormField>
            <TextInput
              type="password"
              value={newPassword}
              onChange={this.handleNewPasswordChanged.bind(this)}
              placeholder="New Password"
            />
          </FormField>
        </Modal>
      </React.Fragment>
    );
  }

  renderCustomerUsers(customer, users) {
    if (!customer || !users) {
      return null;
    }

    const list = users.map(user => {
      return (
        <ListRow key={user.id}>
          <Heading level={4}>{user.email}</Heading>
          <Text>{user.password}</Text>
          <Box gap="small" direction="horizontal" pad={{ right: "small" }}>
            <Button
              onPress={() => this.handleShowPasswordModal(user)}
              label="Change Password"
            />
            <Button
              onPress={() => this.handleShowRemoveUserModal(user)}
              label="Remove"
            />
          </Box>
        </ListRow>
      );
    });

    return <Box>{list}</Box>;
  }

  render() {
    const { activeDialog } = this.store;
    const customer = this.store.customers.items.get(this.state.customerId);
    const users = this.store.customers.customerUsers.get(this.state.customerId);

    return (
      <React.Fragment>
        <SideBar history={this.props.history} location={this.props.location} />
        <Box
          direction="column"
          background={{ color: "light-2" }}
          pad={{ horizontal: "xlarge", vertical: "medium" }}
        >
          {this.renderDialog(activeDialog, customer)}
          <Layer position="bottom" modal={false} margin={{ bottom: "small" }}>
            <Button
              label="Add User"
              icon={<Add />}
              onPress={this.handleShowAddUserModal}
              isReverse
              isPrimary
            />
          </Layer>
          <Box
            pad={{ top: "medium", bottom: "large" }}
            gap="small"
            alignSelf="end"
            align="end"
          >
            <Heading
              level={1}
              size="large"
              style={{ fontWeight: "300", letterSpacing: "-0.033em" }}
            >
              {customer ? customer.name : null}
            </Heading>
            <Text>
              {customer ? customer.id : null}
            </Text>
          </Box>
          <Box gap="hair">{this.renderCustomerUsers(customer, users)}</Box>
        </Box>
      </React.Fragment>
    );
  }
}

export default observer(Customer);
