import { types, getRoot, getEnv, flow } from "mobx-state-tree";

import sort from "../../lib/sort";

const ScreenEvent = types.model("ScreenEvent", {
  timestamp: types.Date,
  localTimestamp: types.Date,
  eventName: types.string,
  sessionId: types.string,
  processId: types.maybeNull(types.string),
  viewName: types.maybeNull(types.string),
  raw: types.string
});

export default types
  .model("ScreenEvents", {
    sortKey: "timestamp",
    items: types.optional(types.array(ScreenEvent), [])
  })
  .views(self => ({
    get sortedItems() {
      return sort(self.items, self.sortKey, true);
    }
  }))
  .actions(self => ({
    reset() {
      self.items = [];
    },
    fetchBySessionId(sessionId) {
      const { cancellationToken, cancel } = getEnv(
        self
      ).connector.getCancellationTokenSource();

      const action = flow(function* fetchBySessionId() {
        self.items = yield getEnv(self).connector.fetchScreenEvents(
          sessionId,
          cancellationToken
        );
      });

      getRoot(self).runAction("fetchScreenEvents", action, cancel);
    }
  }));
