import React from "react";
import { observer } from "mobx-react";
import { Box } from "grommet";

import ensureStore from "../store";

import SideBar from "./SideBar";
import SessionSummary from "../components/Sessions/SessionSummary";
import SearchInput from "../components/SearchInput";

class Sessions extends React.Component {
  constructor(props) {
    super(props);

    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.store = ensureStore();
  }

  componentDidMount() {
    this.parseUrl(this.props);
  }

  parseUrl(props) {
    this.store.sessions.fetch();
  }

  handleSearch(sessionId) {
    this.props.history.push(`/customer/sessions/${sessionId}`);
  }

  handleRefresh() {
    this.parseUrl(this.props);
  }

  renderSessions() {
    return this.store.sessions.sortedItems.map(session => (
      <SessionSummary key={session.id} session={session} />
    ));
  }

  render() {
    return (
      <div className="container">
        <SideBar
          history={this.props.history}
          location={this.props.location}
          onRefresh={this.handleRefresh}
          showDateRangePicker={true}
        />
        <div className="content">
          <SearchInput
            placeholder="Go to session id..."
            onSubmit={this.handleSearch}
          />
          <Box>{this.renderSessions()}</Box>
        </div>
      </div>
    );
  }
}

export default observer(Sessions);
