import React from "react";
import ReactDOM from "react-dom";
import { Grommet } from "grommet";
import { BrowserRouter, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import registerServiceWorker from './registerServiceWorker';
import "react-toastify/dist/ReactToastify.css";

import AdminPortal from "./roots/AdminPortal";
import CustomerPortal from "./roots/CustomerPortal";
import SignIn from "./roots/SignIn";

import "./index.css";

const theme = {
  global: {
    colors: {
      brand: "#039cdb",
      "status-unknown": "#f1f1f1"
    },
    font: {
      family: "din-2014, sans-serif",
      weight: 600
    },
    size: {
      "metric-cell": "116px"
    }
  },
  layer: {
    overlay: {
      background: "rgba(255, 255, 255, 0.9)"
    }
  },
  button: {
    padding: { horizontal: "28px" },
    extend: props => `
		font-family: "din-2014";
		text-transform: uppercase;
		font-weight: 700;
		font-size: 16px;
		letter-spacing: 0.02em;`
  },
  checkBox: {
    border: {
      width: "1px",
      color: "light-4"
    },
    size: "24px",
    check: {
      radius: "0px",
      thickness: "2px"
    },
    extend: props => `
    background-color: #fafafa;
    `
  },
  select: {
    control: {
      extend: props => `
    padding-left: 0px;
    `
    }
  },
  accordion: {
    border: {
      color: "transparent"
    }
  },
  heading: {
    extend: props => `
    line-height: 1em;
		margin: 0`,
    level: {
      6: {
        font: {
          weight: 700
        }
      }
    }
  },
  formField: {
    label: {
      margin: {
        bottom: "hair"
      }
    }
  }
};

ReactDOM.render(
  <BrowserRouter>
    <Grommet theme={theme}>
      <Route exact path="/" component={SignIn} />
      <Route path="/admin" component={AdminPortal} />
      <Route path="/customer" component={CustomerPortal} />
      <ToastContainer />
    </Grommet>
  </BrowserRouter>,
  document.getElementById("root")
);

registerServiceWorker();
