import axios from "axios";

export default function create(options = {}) {
  if (options.instance) {
    return options.instance;
  }

  const axiosInstance = axios.create({
    maxContentLength: 32000,
    ...options
  });

  axiosInstance.isCancel = axios.isCancel;
  return axiosInstance;
}
