import React from "react";
import { observer } from "mobx-react";
import { PulseLoader } from "react-spinners";
import { LinkNext } from "grommet-icons";

import {
  Box,
  Heading,
  Form,
  Text,
  FormField,
  TextInput,
  TextArea,
} from "grommet";

import SideBar from "./SideBar";
import Button from "../components/Buttons/Button";
import LoadingIndicator from "../components/LoadingIndicator";
import GroupedSelector from "../components/GroupedSelector";
import ensureStore from "../store";

class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.handleSend = this.handleSend.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.handleHeadlineChange = this.handleHeadlineChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleContactsSelected = this.handleContactsSelected.bind(this);
    this.handleContactsUnselected = this.handleContactsUnselected.bind(this);

    this.handleActionButtonLabelChange = this.handleActionButtonLabelChange.bind(
      this
    );

    this.handleActionButtonUrlChange = this.handleActionButtonUrlChange.bind(
      this
    );

    this.store = ensureStore();
  }

  componentDidMount() {
    this.store.notifications.fetchContacts();
  }

  handleSubjectChange(e) {
    this.store.notifications.setSubject(e.target.value);
  }

  handleHeadlineChange(e) {
    this.store.notifications.setHeadline(e.target.value);
  }

  handleMessageChange(e) {
    this.store.notifications.setMessage(e.target.value);
  }

  handleActionButtonLabelChange(e) {
    this.store.notifications.setActionButtonLabel(e.target.value);
  }

  handleActionButtonUrlChange(e) {
    this.store.notifications.setActionButtonUrl(e.target.value);
  }

  handleContactsSelected(contacts) {
    this.store.notifications.selectContacts(contacts);
  }

  handleContactsUnselected(contacts) {
    this.store.notifications.unselectContacts(contacts);
  }

  handleSend() {
    this.store.notifications.send();
  }

  render() {
    const { notifications } = this.store;

    const buttonIcon = notifications.isSending ? (
      <PulseLoader size={6} color="#FFF" />
    ) : (
      <LinkNext />
    );

    return (
      <React.Fragment>
        <SideBar
          history={this.props.history}
          location={this.props.location}
          onRefresh={this.handleRefresh}
        />
        <Box
          direction="column"
          background={{ color: "light-2" }}
          pad={{ horizontal: "xlarge", vertical: "medium" }}
        >
          <Box
            direction="row"
            pad={{ top: "medium", bottom: "large" }}
            alignSelf="end"
          >
            <Heading
              level={1}
              size="large"
              style={{ fontWeight: "300", letterSpacing: "-0.033em" }}
            >
              Notifications
            </Heading>
          </Box>

          <Box margin={{ bottom: "xlarge" }}>
            <Form onSubmit={this.handleSend}>
              <Box
                gap="small"
                background="white"
                pad="large"
                margin={{ bottom: "medium" }}
              >
                <FormField label={<Text color="brand">Subject</Text>}>
                  <TextInput
                    value={notifications.subject}
                    onChange={this.handleSubjectChange}
                    size="xlarge"
                  />
                </FormField>
                <FormField label={<Text color="brand">Headline</Text>}>
                  <TextInput
                    value={notifications.headline}
                    onChange={this.handleHeadlineChange}
                    size="xlarge"
                  />
                </FormField>
                <Box height="small" background="light-1" margin={{ vertical: "medium" }}>
                  <TextArea
                    value={notifications.message}
                    onChange={this.handleMessageChange}
                    size="large"
                    fill={true}
                    resize={false}
                    style={{ border: "1px solid #ddd" }}
                  />
                </Box>

                <Box direction="row" gap="medium" fill={true}>
                  <Box fill={true}>
                    <FormField label={<Text color="brand">Button Label</Text>}>
                      <TextInput
                        value={notifications.actionButtonLabel}
                        onChange={this.handleActionButtonLabelChange}
                        size="xlarge"
                      />
                    </FormField>
                  </Box>

                  <Box fill={true}>
                    <FormField label={<Text color="brand">Button URL</Text>}>
                      <TextInput
                        value={notifications.actionButtonUrl}
                        onChange={this.handleActionButtonUrlChange}
                        size="xlarge"
                      />
                    </FormField>
                  </Box>
                </Box>
              </Box>

              <LoadingIndicator
                isLoading={notifications.isLoading}
                size={8}
                color="#333"
              />

              <GroupedSelector
                groups={notifications.contactGroups}
                groupLabelKey="name"
                itemsKey="contacts"
                itemLabelKey="name"
                itemFallbackLabelKey="email"
                itemContentKey="email"
                onSelect={this.handleContactsSelected}
                onUnselect={this.handleContactsUnselected}
                selectedItems={notifications.selectedContacts}
              />

              <Button
                type="submit"
                label={`Send to ${notifications.selectedEmails.length} contacts`}
                isDisabled={!notifications.canSend}
                margin={{ top: "large" }}
                icon={buttonIcon}
                isReverse
                isPrimary
              />
            </Form>
          </Box>
        </Box>
      </React.Fragment>
    );
  }
}

export default observer(Notifications);
