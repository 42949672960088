import React from "react";
import moment from "moment";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import { Box, Calendar, DropButton, Text } from "grommet";
import { FormDown } from "grommet-icons";

class DateRangePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isActive: false };

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleDateChange(value) {
    this.props.onDateChange(value);
    this.setState({ isActive: false });
  }

  handleClose() {
    this.setState({ isActive: false });
  }

  handleOpen() {
    this.setState({ isActive: true });
  }

  render() {
    const { isActive } = this.state;
    const {
      value,
      placeholder = "Select date",
      minDate = "1970-01-01",
      maxDate = new Date().toISOString()
    } = this.props;

    return (
      <DropButton
        open={isActive}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        dropContent={
          <Calendar
            date={value}
            bounds={[minDate, maxDate]}
            onSelect={this.handleDateChange}
            animate={false}
          />
        }
      >
        <Box
          direction="row"
          gap="medium"
          align="center"
          background="light-1"
          height="36px"
          round="18px"
          pad={{ left: "medium", right: "medium" }}
        >
          <Text size="medium" margin={{ left: "xsmall" }}>
            {value ? moment(value).format("LL") : placeholder}
          </Text>
          <FormDown color="brand" />
        </Box>
      </DropButton>
    );
  }
}

DateRangePicker.propTypes = {
  value: PropTypes.string.isRequired,
  onDateChange: PropTypes.func.isRequired
};

export default observer(DateRangePicker);
