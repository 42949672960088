import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Image, Button, Box } from "grommet";

import {
  Analytics,
  Monitor,
  Cart,
  Alert,
  Group,
  Map,
  CoatCheck,
  MailOption,
  PowerForceShutdown
} from "grommet-icons";

import ensureStore from "../store";
import SideBarButton from "../components/Buttons/SideBarButton";
import logo from "../assets/logo_small.png";

const iconSize = "22px";
const iconColor = "dark-2";

const links = [
  {
    key: "screens",
    url: "/customer/screens",
    icon: <Monitor color={iconColor} size={iconSize} />,
    isAdmin: false
  },
  {
    key: "map-customer",
    url: "/customer/map",
    icon: <Map color={iconColor} size={iconSize} />,
    isAdmin: false
  },
  {
    key: "checkout",
    url: "/customer/checkouts",
    icon: <Cart color={iconColor} size={iconSize} />,
    isAdmin: false
  },
  {
    key: "insights",
    url: "/customer/insights",
    icon: <Analytics color={iconColor} size={iconSize} />,
    isAdmin: false
  },
  {
    key: "products",
    url: "/customer/products",
    icon: <CoatCheck color={iconColor} size={iconSize} />,
    isAdmin: false
  },
  /*
  {
    key: "menus",
    url: "/customer/menus",
    icon: <Drag color={iconColor} size={iconSize} />,
    isAdmin: false
  },*/
  {
    key: "monitoring",
    url: "/admin/monitoring",
    icon: <Monitor color={iconColor} size={iconSize} />,
    isAdmin: true
  },
  {
    key: "map-admin",
    url: "/admin/map",
    icon: <Map color={iconColor} size={iconSize} />,
    isAdmin: true
  },
  {
    key: "customers",
    url: "/admin/customers",
    icon: <Group color={iconColor} size={iconSize} />,
    isAdmin: true
  },
  {
    key: "errors",
    url: "/admin/errors",
    icon: <Alert color={iconColor} size={iconSize} />,
    isAdmin: true
  },
  {
    key: "notifications",
    url: "/admin/notifications",
    icon: <MailOption color={iconColor} size={iconSize} />,
    isAdmin: true
  }
];

class SideBar extends React.Component {
  constructor(props) {
    super(props);

    this.store = ensureStore();
  }

  renderButtons() {
    const { pathname } = this.props.location;
    const { isAdmin } = this.store.identity;

    return links
      .filter(l => l.isAdmin === isAdmin)
      .map(({ url, icon, key }) => (
        <SideBarButton key={key} link={url} isActive={pathname.startsWith(url)}>
          {icon}
        </SideBarButton>
      ));
  }

  render() {
    const { children } = this.props;
    const { identity } = this.store;

    if (!identity.isSignedIn) {
      return null;
    }

    return (
      <nav
        className="sidebar"
        style={{
          display: "flex",
          position: "fixed",
          boxSizing: "border-box",
          top: 0,
          left: 0,
          bottom: 0,
          zIndex: 3
        }}
      >
        <Box
          direction="column"
          align="center"
          justify="between"
          alignSelf="stretch"
          pad={{ top: "30px", left: "0", bottom: "20px", right: "0" }}
          width="56px"
          elevation="xsmall"
        >
          <Box align="center" alignSelf="stretch">
            <Image
              src={logo}
              alt="Touchtech"
              width="38px"
              height="36px"
              margin={{ left: "2px", bottom: "70px" }}
            />
            {this.renderButtons()}
            {children}
          </Box>
          <Button
            onClick={() => identity.signOut()}
            className="topbar-button"
            pad="0px"
            plain
          >
            <PowerForceShutdown color={iconColor} size="28px" />
          </Button>
        </Box>
      </nav>
    );
  }
}

SideBar.propTypes = {
  children: PropTypes.any,
  history: PropTypes.any,
  location: PropTypes.any
};

export default observer(SideBar);
