import { types, getRoot, getEnv, flow } from "mobx-state-tree";
import moment from "moment";

const Term = types.model("Term", {
  name: types.string,
  count: types.number,
});

const Exception = types.model("Exception", {
  message: types.maybeNull(types.string),
  source: types.maybeNull(types.string),
});

const GroupedError = types.model("GroupedError", {
  key: types.string,
  id: types.string,
  count: types.number,
  timestamp: types.string,
  exception: types.maybeNull(Exception),
});

const Group = types
  .model("Group", {
    count: types.number,
    key: types.string,
    id: types.string,
    exception: types.maybeNull(Exception),
    screenId: types.array(Term),
    kaseyaScreenName: types.array(Term),
    customerId: types.array(Term),
    customerName: types.array(Term),
    clientId: types.array(Term),
    sourceContext: types.array(Term),
    locationPath: types.array(Term),
    locationHost: types.array(Term),
    locationSearch: types.array(Term),
    requestPath: types.array(Term),
    machineName: types.array(Term),
    email: types.array(Term),
    errors: types.maybeNull(types.array(GroupedError)),
    timestamp: types.string,
    pendingActions: types.optional(types.map(types.boolean), {}),
  });

export default types
  .model("Errors", {
    items: types.array(Group),
    fromDate: types.optional(
      types.Date,
      moment()
        .subtract(3, "hours")
        .toDate()
    ),
    toDate: types.optional(types.Date, new Date()),
  })
  .views(self => ({
    get isLoading() {
      return getRoot(self).pendingActions.has("fetchErrors");
    },
  }))
  .actions(self => ({
    reset() {
      self.items = [];
    },

    /**
     * Fetches a list of errors
     */
    fetch() {
      const { cancellationToken, cancel } = getEnv(
        self
      ).connector.getCancellationTokenSource();

      const action = flow(function* fetch() {
        const errors = yield getEnv(self).connector.fetchErrors(
          self.fromDate,
          self.toDate,
          cancellationToken
        );

        self.items = errors.groups;
      });

      return getRoot(self).runAction("fetchErrors", action, cancel);
    },
  }));
