import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Stack, Meter as GrommetMeter, Box } from "grommet";

class Meter extends React.Component {
  render() {
    const {
      value,
      icon,
      label,
      description,
      type = "circle",
      size = "xxsmall",
      thickness = "xxsmall",
      background = "light-2",
      minThreshold = null,
      maxThreshold = null
    } = this.props;

    const isBelowMin = minThreshold !== null && value < minThreshold;
    const isAboveMax = maxThreshold !== null && value > maxThreshold;

    const isError = isBelowMin || isAboveMax;

    return (
      <Box alignSelf="center">
        <Stack anchor="center">
          <GrommetMeter
            a11yTitle={description}
            round={true}
            size={size}
            thickness={thickness}
            background={background}
            values={[
              {
                value,
                label,
                color: isError ? "status-error" : "status-ok"
              }
            ]}
            type={type}
          />
          {icon}
        </Stack>
      </Box>
    );
  }
}

Meter.propTypes = {
  value: PropTypes.number.isRequired,
  minThreshold: PropTypes.number,
  maxThreshold: PropTypes.number,
  icon: PropTypes.object,
  type: PropTypes.string,
  size: PropTypes.string,
  thickness: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  background: PropTypes.string
};

export default observer(Meter);
