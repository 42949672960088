import React from "react";
import { observer } from "mobx-react";
import ReactJson from "react-json-view";
import { Link } from "react-router-dom";
import { Box, Heading, Text } from "grommet";
import qs from "qs";

import ensureStore from "../store";

import SideBar from "./SideBar";
import Barcode from "../components/Barcode";
import OptionSelector from "../components/OptionSelector";
import SearchInput from "../components/SearchInput";

import "./Products.css";

class Products extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      articleNumber: null,
      currency: null,
      locale: null
    };

    this.handleCurrencyChanged = this.handleCurrencyChanged.bind(this);
    this.handleLocaleChanged = this.handleLocaleChanged.bind(this);
    this.handleSearch = this.handleSearch.bind(this);

    this.store = ensureStore();
  }

  componentDidMount() {
    this.parseUrl(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.parseUrl(nextProps);
  }

  parseUrl(props) {
    const { articleNumber } = props.match.params;

    const { locale, currency } = qs.parse(props.location.search, {
      ignoreQueryPrefix: true
    });

    const [languageCode, countryCode] = locale ? locale.split("-") : [];

    if (articleNumber !== this.state.articleNumber) {
      this.store.products.fetchByArticleNumber(articleNumber);
    }

    this.setState({
      articleNumber,
      locale,
      languageCode,
      countryCode,
      currency
    });
  }

  handleLocaleChanged(locale) {
    const { currency } = this.state;
    this.props.history.replace(`?${qs.stringify({ currency, locale })}`);
  }

  handleCurrencyChanged(currency) {
    const { locale } = this.state;
    this.props.history.replace(`?${qs.stringify({ currency, locale })}`);
  }

  handleSearch(articleNumber) {
    const query = this.getQuery();
    this.props.history.push(`/customer/products/${articleNumber}${query}`);
  }

  getQuery() {
    const { currency, locale } = this.state;
    return `?${qs.stringify({ currency, locale })}`;
  }

  getLocalizedString(localizedString) {
    if (!localizedString) {
      return "";
    }

    const { defaultValue, localizations } = localizedString;

    if (!localizations.length || !this.state.languageCode) {
      return defaultValue;
    }

    const localization = localizations.find(
      ({ languageCode, countryCode }) =>
        languageCode === this.state.languageCode &&
        (countryCode === this.state.countryCode || !this.state.countryCode)
    );

    return localization ? localization.value : defaultValue;
  }

  renderMedia({ articleNumber, media }) {
    const items = media.map((media, i) => {
      const key = `${articleNumber}-media-${i}`;

      if (media.type === "image") {
        return <img key={key} src={media.url} alt={media.url} />;
      } else {
        return <div key={key}>{media.url}</div>;
      }
    });

    return <div className="media">{items}</div>;
  }

  renderPrice({ prices }) {
    if (!prices.length) {
      return null;
    }

    const price = this.state.currency
      ? prices.find(p => p.currency === this.state.currency)
      : prices[0];

    if (!price) {
      return null;
    }

    const { originalPrice, salePrice, currency, unit, comparisonUnit } = price;
    const localizedComparisonUnit = this.getLocalizedString(comparisonUnit);
    const localizedUnit = this.getLocalizedString(unit);
    const comparisonPriceItems = [];
    const priceItems = [];

    if (salePrice) {
      priceItems.push(
        <div key="sale-price" className="sale-price">
          {salePrice.price} {currency} {localizedUnit}
        </div>
      );
    }

    if (salePrice && salePrice.comparisonPrice) {
      comparisonPriceItems.push(
        <div key="sale-price" className="sale-price comparison-price">
          {salePrice.comparisonPrice} {currency} {localizedComparisonUnit}
        </div>
      );
    }

    if (originalPrice) {
      priceItems.push(
        <div
          key="original-price"
          className={`${salePrice ? "sale-" : ""}original-price`}
        >
          {originalPrice.price} {currency} {localizedUnit}
        </div>
      );
    }

    if (originalPrice && originalPrice.comparisonPrice) {
      comparisonPriceItems.push(
        <div
          key="original-price"
          className={`${
            salePrice
              ? "sale-original-price comparison-price"
              : "original-price comparison-price"
          }`}
        >
          {originalPrice.comparisonPrice} {currency} {localizedComparisonUnit}
        </div>
      );
    }

    return (
      <Box margin={{ top: "large" }}>
        <div className="price">{priceItems}</div>
        <div className="price">{comparisonPriceItems}</div>
      </Box>
    );
  }

  renderAttributes({ attributes }) {
    if (!attributes.length) {
      return null;
    }

    const items = attributes.map(({ attributeId, value }) => (
      <Box key={`${attributeId}-${value}`} direction="row">
        <div className="attribute-id">{attributeId}:</div>
        <div className="attribute-value">{value}</div>
      </Box>
    ));

    return <div className="attributes">{items}</div>;
  }

  renderVariant(variant) {
    if (!variant) {
      return null;
    }

    const query = this.getQuery();

    return (
      <Link
        to={`/customer/products/${variant.articleNumber}${query}`}
        key={variant.articleNumber}
      >
        <div className="product-variant" key={variant.articleNumber}>
          {this.renderMedia(variant)}
          <div className="product-variant-info">
            <h3>{this.getLocalizedString(variant.brand)}</h3>
            <h3>{this.getLocalizedString(variant.name)}</h3>
            <h4>Article number: {variant.articleNumber}</h4>
            {this.renderPrice(variant)}
          </div>
        </div>
      </Link>
    );
  }

  renderDescription(product) {
    const localizedDescription = this.getLocalizedString(product.description);
    const segments = localizedDescription.split("\r\n");

    const descriptionWithLineBreaks = segments.map((segment, i) => (
      <React.Fragment key={i}>
        {segment}
        <br />
      </React.Fragment>
    ));

    return <Box margin={{ bottom: "medium" }}>{descriptionWithLineBreaks}</Box>;
  }

  renderProduct(product) {
    if (!product) {
      return null;
    }

    const variants = product.variants.map(variant =>
      this.renderVariant(variant)
    );
    
    const selectedVariant = product.variants.find(
      v => v.articleNumber === this.state.articleNumber
    );

    const media =
      selectedVariant && selectedVariant.media.length
        ? selectedVariant.media
        : product.media;

    const mergedProduct = { ...product, ...selectedVariant, media };

    return (
      <Box key={mergedProduct.articleNumber} background={{ color: "white" }}>
        <div className="product">
          {this.renderMedia(mergedProduct)}
          <Box pad={{ vertical: "large", horizontal: "large" }} style={{ width: "38vw" }}>
            <Heading
              level={4}
              size="small"
              style={{ textTransform: "uppercase" }}
            >
              {this.getLocalizedString(mergedProduct.brand)}
            </Heading>
            <Heading level={2} size="large">
              {this.getLocalizedString(mergedProduct.name)}
            </Heading>
            {this.renderPrice(mergedProduct)}
            <Text margin={{ top: "large", bottom: "medium"}}>Article number: {mergedProduct.articleNumber}</Text>
            {this.renderDescription(mergedProduct)}
            {this.renderAttributes(mergedProduct)}

            <Box margin={{ top: "medium"}}>
            <Barcode value={mergedProduct.gtin} />
            </Box>
            
          </Box>
          <div className="product-variants">
            <h2>
              {variants.length} {variants.length === 1 ? "variant" : "variants"}
            </h2>
            {variants}
          </div>
        </div>
        <Box pad="medium">
          <ReactJson
            name="product"
            theme="bright:inverted"
            collapsed={true}
            displayObjectSize={false}
            displayDataTypes={false}
            src={product.json}
          />
        </Box>
      </Box>
    );
  }

  render() {
    const { localeOptions, currencyOptions } = this.store;

    const products = this.store.products.sortedItems.map(product =>
      this.renderProduct(product)
    );

    return (
      <React.Fragment>
        <SideBar history={this.props.history} location={this.props.location} />
        <Box
          direction="column"
          background={{ color: "light-2" }}
          pad={{ horizontal: "xlarge", vertical: "medium" }}
          gap="small"
        >
          <SearchInput
            onSubmit={this.handleSearch}
            placeholder="Search for article number..."
          />
          <Box
            direction="row"
            pad={{ top: "medium", bottom: "xlarge" }}
            alignSelf="end"
          >
            <Heading
              level={1}
              size="large"
              style={{ fontWeight: "300", letterSpacing: "-0.033em" }}
            >
              Products
            </Heading>
          </Box>
          <Box direction="row" fill="horizontal" gap="xsmall">
            <OptionSelector
              options={localeOptions}
              onChange={this.handleLocaleChanged}
              value={this.state.locale}
              placeholder="Language"
            />
            <OptionSelector
              options={currencyOptions}
              onChange={this.handleCurrencyChanged}
              value={this.state.currency}
              placeholder="Currency"
            />
          </Box>
          {products}
        </Box>
      </React.Fragment>
    );
  }
}

export default observer(Products);
