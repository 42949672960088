import { types, getRoot, getEnv, flow } from "mobx-state-tree";

import mapToList from "../../lib/mapToList";
import sort from "../../lib/sort";
import User from "./user";

const Customer = types.model("Customer", {
  id: types.identifier,
  name: types.string
});

export default types
  .model("Customers", {
    sortKey: "name",
    items: types.optional(types.map(Customer), {}),
    customerUsers: types.optional(types.map(types.array(User)), {}),
    usersSortKey: "email"
  })
  .views(self => ({
    get sortedItems() {
      return sort(mapToList(self.items, true), self.sortKey);
    },

    get isLoading() {
      return getRoot(self).pendingActions.has("fetchCustomers");
    }
  }))
  .actions(self => ({
    reset() {
      self.items = {};
      self.customerUsers = {};
    },
    /**
     * Fetches a customer by its ID
     */
    fetchById(customerId) {
      const { cancellationToken, cancel } = getEnv(
        self
      ).connector.getCancellationTokenSource();

      const action = flow(function* fetchById() {
        const customer = yield getEnv(self).connector.fetchCustomer(
          customerId,
          cancellationToken
        );

        self.items.set(customerId, customer);
      });

      return getRoot(self).runAction(
        `fetchCustomerById-${customerId}`,
        action,
        cancel
      );
    },

    /**
     * Fetches a list of customers
     */
    fetch(skip, limit) {
      const { cancellationToken, cancel } = getEnv(
        self
      ).connector.getCancellationTokenSource();

      const action = flow(function* fetch() {
        const customers = yield getEnv(self).connector.fetchCustomers(
          skip,
          limit,
          cancellationToken
        );
        customers.forEach(customer => self.items.set(customer.id, customer));
      });

      return getRoot(self).runAction("fetchCustomers", action, cancel);
    },
    /**
     * Creates a new customer
     */
    create(name, throwExceptions) {
      const { cancellationToken, cancel } = getEnv(
        self
      ).connector.getCancellationTokenSource();

      const action = flow(function* fetch() {
        const customer = yield getEnv(self).connector.createCustomer(
          name,
          cancellationToken
        );
        self.items.set(customer.id, customer);

        getRoot(self).createToast(
          `Successfully created customer ${name}.`
        );
      });

      return getRoot(self).runAction(
        `createCustomer-${name}`,
        action,
        cancel,
        throwExceptions
      );
    },
    fetchUsers(customerId) {
      const { cancellationToken, cancel } = getEnv(
        self
      ).connector.getCancellationTokenSource();

      const action = flow(function* fetch() {
        const users = yield getEnv(self).connector.fetchCustomerUsers(
          customerId,
          cancellationToken
        );
        self.customerUsers.set(customerId, users);
      });

      return getRoot(self).runAction(
        `fetchCustomerUsers-${customerId}`,
        action,
        cancel
      );
    },
    createUser(customerId, email, throwExceptions = false) {
      const { cancellationToken, cancel } = getEnv(
        self
      ).connector.getCancellationTokenSource();

      const action = flow(function* fetch() {
        const json = yield getEnv(self).connector.createCustomerUser(
          customerId,
          email,
          cancellationToken
        );
        const customerUsers = self.customerUsers.get(customerId) || [];
        customerUsers.push(json);

        self.customerUsers.set(customerId, customerUsers);

        getRoot(self).createToast(
          `Successfully created user ${email} with password ${json.password}`
        );
      });

      return getRoot(self).runAction(
        `createCustomerUser-${customerId}`,
        action,
        cancel,
        throwExceptions
      );
    },
    removeUser(customerId, userId, throwExceptions) {
      const { cancellationToken, cancel } = getEnv(
        self
      ).connector.getCancellationTokenSource();

      const action = flow(function* fetch() {
        yield getEnv(self).connector.removeCustomerUser(
          userId,
          cancellationToken
        );
        const users = self.customerUsers
          .get(customerId)
          .filter(({ id }) => id !== userId);

        self.customerUsers.set(customerId, users);
      });

      return getRoot(self).runAction(
        `createCustomerUser-${customerId}`,
        action,
        cancel,
        throwExceptions
      );
    }
  }));
