import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { ResponsiveBar } from "@nivo/bar";

class BarChart extends React.Component {
  render() {
    if (!this.props.data || !this.props.data.length) {
      return null;
    }

    return (
      <ResponsiveBar
        indexBy="weekOfYear"
        id={this.props.id || "bar"}
        data={this.props.data}
        colorBy="id"
        borderWidth={1}
        borderColor="inherit:darker(0.3)"
        keys={this.props.keys}
        axisLeft={null}
        enableGridX={false}
        enableGridY={false}
        animate={false}
        motionStiffness={90}
        motionDamping={15}
        labelSkipHeight={30}
        margin={{
          top: 0,
          right: 35,
          bottom: 35,
          left: 35
        }}
      />
    );
  }
}

BarChart.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired
};

export default observer(BarChart);
