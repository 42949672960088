import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { ResponsiveHeatMap } from "@nivo/heatmap";

class HeatmapChart extends React.Component {
  render() {
    const { id, keyName = "key" } = this.props;

    const keySet = this.props.data.reduce((result, item) => {
      Object.keys(item).forEach(key => {
        if (key !== keyName) {
          result.add(key);
        }
      });

      return result;
    }, new Set());

    // Ensure that all fields default to 0
    const data = this.props.data.map(item => {
        const result = {...item};

        for (const key of keySet) {
            result[key] = item[key] || 0;
        }

        return result;
    })

    return (
      <ResponsiveHeatMap
        id={id || "heatmap"}
        data={data}
        keys={[...keySet]}
        indexBy={"key"}
        margin={{
          top: 70,
          right: 60,
          bottom: 20,
          left: 200
        }}
        axisTop={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 10,
          tickRotation: 0,
          legendOffset: 50
        }}
        axisLeft={{
          orient: "right",
          tickSize: 0,
          tickPadding: 0,
          tickRotation: 0,
          legendPosition: "middle",
          legendOffset: 10
        }}
        cellOpacity={1}
        cellBorderColor="inherit:darker(0.1)"
        animate={false}
        hoverTarget="rowColumn"
        cellHoverOthersOpacity={0.1}
        cellShape="circle"
        forceSquare={false}
        sizeVariation={0.95}
        enableLabels={false}
        enableGridY={true}
        padding={0}
      />
    );
  }
}

HeatmapChart.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.array,
  keyName: PropTypes.string,
  valueName: PropTypes.string,
  labelName: PropTypes.string
};

export default observer(HeatmapChart);
