import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Heading, Box, Text } from "grommet";

import ListRow from "../ListRow";

class MenuSummary extends React.Component {
  renderHeroImage({ heroImage }) {
    if (!heroImage) {
      return null;
    }

    return <img src={heroImage.defaultValue.url} alt="Hero" />;
  }

  renderMenuItems({ items }) {
    return items.map(item => (
      <React.Fragment key={item.id}>
        <img
          src={item.tileImage.defaultValue.url}
          alt="Menu item"
          style={{ height: "50px" }}
        />
      </React.Fragment>
    ));
  }

  render() {
    const { menu } = this.props;

    if (!menu) {
      return null;
    }

    const { id, screenCount } = menu;

    return (
      <ListRow link={`/customer/menus/${id}`}>
        <Box>
          <Heading level={2} size="medium">
            {id}
          </Heading>
          <Text size="small">
            {screenCount ? `${screenCount} screens` : null}
          </Text>
        </Box>
        <Box direction="row" pad={{right: "small"}}>{this.renderMenuItems(menu)}</Box>
      </ListRow>
    );
  }
}

MenuSummary.propTypes = {
  menu: PropTypes.object
};

export default observer(MenuSummary);
