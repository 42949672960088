import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { Box } from "grommet";

class ListRow extends React.Component {
  renderContent() {
    return (
      <Box
        direction="row"
        align="center"
        justify="between"
        background={{ color: "white" }}
        pad={{ left: "medium", right: "xsmall", vertical: "medium" }}
        animation="fadeIn"
        fill="horizontal"
        alignSelf="stretch"
      >
        {this.props.children}
      </Box>
    );
  }

  render() {
    if (this.props.link) {
      return <Link to={this.props.link}>{this.renderContent()}</Link>;
    } else {
      return this.renderContent();
    }
  }
}

ListRow.propTypes = {
  children: PropTypes.any,
  link: PropTypes.string,
};

export default observer(ListRow);
