import { flatten } from "flatnest";
import Papa from "papaparse";

export default function exportCsv(filename, jsonArray) {
  const fields = new Set();

  const flattenedData = jsonArray.map(item => {
    const flattenedItem = flatten(item);
    Object.keys(flattenedItem).forEach(key => fields.add(key));

    return flattenedItem;
  });

  const normalizedData = flattenedData.map(item => {
    const result = { ...item };

    [...fields].sort().forEach(key => {
      result[key] = item[key] !== undefined ? item[key] : null;
    });

    return result;
  });

  const csv = Papa.unparse(normalizedData, { fields: [...fields] });
  const blob = new Blob([csv]);

  const a = window.document.createElement("a");
  a.href = window.URL.createObjectURL(blob, { type: "text/plain" });
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
