export default function listToMap(items, keyName = 'key') {
    if (!items) {
        return {};
    }
    
	return items.reduce((map, item) => {
        const key = item[keyName];
        const value = {...item};
        delete value[keyName];

        map[key] = value;

        return map;
    }, {})
}
