import React, { Component } from "react";
import { observer } from "mobx-react";
import { Redirect } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { LinkNext } from "grommet-icons";
import { Layer, TextInput, Box, Heading, Image } from "grommet";

import ensureStore from "../store";
import Button from "../components/Buttons/Button";
import background from "../assets/background.jpg";
import logo from "../assets/logo.png";

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.store = ensureStore(props.store);
    this.state = { email: "", password: "" };
  }

  handleSubmit(e) {
    e.preventDefault();
    this.store.identity.signIn(this.state.email, this.state.password);
  }

  handleEmailChanged(e) {
    const email = e.target.value;
    this.setState({ email });
  }

  handlePasswordChanged(e) {
    const password = e.target.value;
    this.setState({ password });
  }

  renderMessage() {
    if (!this.store.identity.error) {
      return null;
    }

    return <Heading level={4}>{this.store.identity.error}</Heading>;
  }

  render() {
    if (this.store.identity.isSignedIn) {
      const { state } = this.props.location;
      const referrerRoute = state ? state.referrer : null;

      const defaultRoute = this.store.identity.isAdmin
        ? "/admin"
        : "/customer/screens";

      return <Redirect to={referrerRoute || defaultRoute} />;
    }

    const buttonIcon = this.store.identity.isSigningIn ? (
      <PulseLoader size={6} color="#FFF" />
    ) : (
      <LinkNext />
    );

    return (
      <div
        className="sign-in-container"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundImage: `url('${background}')`
        }}
      >
        <Layer modal={false} style={{ background: "transparent" }}>
          <form onSubmit={this.handleSubmit.bind(this)}>
            <Box
              background="rgba(255,255,255,0.94)"
              direction="column"
              align="center"
              pad={{ vertical: "large", horizontal: "medium" }}
              width="medium"
              gap="medium"
            >
              <Image
                src={logo}
                alt="Touchtech"
                margin={{ bottom: "medium" }}
                width="165px"
                height="44px"
              />
              {this.renderMessage()}
              <TextInput
                id="sign-in-email-input"
                type="email"
                value={this.state.email}
                onChange={this.handleEmailChanged.bind(this)}
                autoComplete="email"
                placeholder="Email"
                style={{ borderRadius: 0, fontFamily: "din-2014" }}
              />
              <TextInput
                id="sign-in-password-input"
                type="password"
                value={this.state.password}
                onChange={this.handlePasswordChanged.bind(this)}
                autoComplete="current-password"
                placeholder="Password"
                style={{ borderRadius: 0, fontFamily: "din-2014" }}
              />
              <Button
                type="submit"
                label="Sign In"
                icon={buttonIcon}
                isReverse
                isPrimary
              />
            </Box>
          </form>
        </Layer>
      </div>
    );
  }
}

export default observer(SignIn);
