import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { ResponsivePie } from "@nivo/pie";

class PieChart extends React.Component {
  render() {
    const {
      id,
      data,
      keyName = "key",
      valueName = "value",
      labelName = "label"
    } = this.props;

    if (!data) {
      return null;
    }

    const mappedData = data.map(item => ({
      id: item[keyName],
      value: item[valueName],
      label: item[labelName].toUpperCase()
    }));

    return (
      <ResponsivePie
        id={id || "pie"}
        data={mappedData}
        innerRadius={0.6}
        padAngle={0.25}
        cornerRadius={0}
        colorBy="id"
        borderWidth={1}
        borderColor="inherit:darker(0.3)"
        radialLabel="label"
        radialLabelsSkipAngle={13}
        radialLabelsTextXOffset={5}
        radialLabelsTextColor="#333333"
        radialLabelsLinkOffset={5}
        radialLabelsLinkDiagonalLength={24}
        radialLabelsLinkHorizontalLength={24}
        radialLabelsLinkStrokeWidth={1}
        slicesLabelsSkipAngle={13}
        slicesLabelsTextColor="#333333"
        animate={false}
        motionStiffness={90}
        motionDamping={15}
        margin={{
          top: 65,
          right: 80,
          bottom: 65,
          left: 80
        }}
      />
    );
  }
}

PieChart.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.array,
  keyName: PropTypes.string,
  valueName: PropTypes.string,
  labelName: PropTypes.string
};

export default observer(PieChart);
