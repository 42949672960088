import axios from "axios";
import qs from "qs";

import createApi from "./lib/api";
import createAuth from "./lib/auth";
import createClient from "./lib/client";
import errors from "./lib/errors";

const throat = require("throat");

/**
 * Factory that creates a Connector instance
 * @param {object} options - Options
 * @return {Connector} Connector connector
 */
export default function create(options) {
  return new Connector(options);
}

function Connector(options) {
  this.REQUEST_CANCELLED = errors.REQUEST_CANCELLED;
  this.ACCESS_TOKEN_EXPIRED = errors.ACCESS_TOKEN_EXPIRED;

  this.auth = createAuth(options.auth);

  const defaultClient = createClient();
  const coreClient = createClient({
    paramsSerializer: params => qs.stringify(params, { arrayFormat: "repeat" })
  });

  this.insightsApi = createApi(
    options.insightsApiUrl,
    defaultClient,
    this.auth
  );

  this.customerApi = createApi(options.customerApiUrl, coreClient, this.auth);
  this.importApi = createApi(options.importApiUrl, coreClient, this.auth);
  this.portalApi = createApi(options.portalApiUrl, coreClient, this.auth);
  this.moneyApi = createApi(options.moneyApiUrl, defaultClient, this.auth);
  this.mailApi = createApi(options.mailApiUrl, defaultClient, this.auth);
}

/**
 * Customers
 */
Connector.prototype.fetchCustomers = function fetchCustomers(
  skip,
  limit,
  cancellationToken
) {
  return this.customerApi.get({
    cancellationToken,
    route: `/customers`,
    params: { skip, limit }
  });
};

Connector.prototype.fetchCustomer = async function fetchCustomer(
  customerId,
  cancellationToken
) {
  const customer = await this.customerApi.get({
    cancellationToken,
    route: `/customers/${customerId}`
  });

  if (!customer) {
    throw new Error(`Customer ${customerId} does not exist`);
  }

  return customer;
};

Connector.prototype.createCustomer = function createCustomer(
  name,
  cancellationToken
) {
  return this.customerApi.post({
    cancellationToken,
    route: `/customers`,
    data: { name }
  });
};

/**
 * Users
 */
Connector.prototype.fetchCustomerUsers = function fetchCustomerUsers(
  customerId,
  skip,
  limit,
  cancellationToken
) {
  return this.customerApi.get({
    cancellationToken,
    route: `/users`,
    params: {
      customerId,
      skip,
      limit
    }
  });
};

Connector.prototype.createCustomerUser = function createCustomerUser(
  customerId,
  email,
  cancellationToken
) {
  return this.customerApi.post({
    cancellationToken,
    route: `/users`,
    data: {
      email,
      customerId
    }
  });
};

Connector.prototype.removeCustomerUser = function removeCustomerUser(
  userId,
  cancellationToken
) {
  return this.customerApi.delete({
    cancellationToken,
    route: `/users/${userId}`
  });
};

Connector.prototype.changeUserPassword = function(
  userId,
  oldPassword,
  newPassword,
  cancellationToken
) {
  return this.customerApi.put({
    cancellationToken,
    route: `/users/${userId}/changePassword`,
    data: {
      oldPassword,
      newPassword
    }
  });
};

/**
 * Screens
 */
Connector.prototype.fetchScreens = async function fetchScreens(
  skip,
  limit,
  cancellationToken
) {
  const screens = await this.portalApi.get({
    cancellationToken,
    route: "/screens",
    params: { skip, limit }
  });

  return Promise.all(
    screens.map(
      throat(25, screen => this.transformSettings(screen, cancellationToken))
    )
  );
};

Connector.prototype.fetchScreen = async function fetchScreen(
  screenId,
  cancellationToken
) {
  const screen = await this.portalApi.get({
    cancellationToken,
    route: `/screens/${screenId}`
  });

  return this.transformSettings(screen, cancellationToken);
};

Connector.prototype.transformSettings = async function transformSettings(
  screen,
  cancellationToken
) {
  const settingKeys = screen.settings.map(setting => setting.key);

  // Fetch all setting constraints
  const constraints = await Promise.all(
    settingKeys.map(
      throat(25, key => this.fetchConstraints(key, cancellationToken))
    )
  );

  // Assign constraints to screen settings
  const settings = screen.settings.map(setting => {
    const item = constraints.find(c => c.settingKey === setting.key);
    return { ...setting, constraints: item };
  });

  return { ...screen, settings: this.settingsToMap(settings) };
};

Connector.prototype.updateScreen = async function updateScreen(
  screenId,
  data,
  cancellationToken
) {
  const screen = await this.portalApi.patch({
    cancellationToken,
    route: `/screens/${screenId}`,
    data
  });

  return this.transformSettings(screen);
};

/**
 * Settings
 */
Connector.prototype.updateSetting = function updateSetting(
  screenId,
  key,
  value,
  cancellationToken
) {
  return this.portalApi.patch({
    cancellationToken,
    route: `/screens/${screenId}`,
    data: { settings: [{ key, value }] }
  });
};

// TODO: Remove this when constraints are embedded in settings
Connector.prototype.fetchConstraints = async function fetchConstraints(
  key,
  cancellationToken
) {
  const constraints = await this.portalApi.get({
    cancellationToken,
    route: `/settings/${key}/constraints`,
    cacheMaxAge: 3600000
  });

  return { ...constraints, settingKey: key };
};

Connector.prototype.settingsToMap = function settingsToMap(settings) {
  return settings.reduce((result, setting) => {
    result[setting.key] = setting;
    return result;
  }, {});
};

/**
 * Menus
 */
Connector.prototype.fetchMenus = async function fetchMenus(cancellationToken) {
  const { options } = await this.portalApi.get({
    cancellationToken,
    route: `/settings/menu/constraints`
  });

  return Promise.all(
    options.map(option => this.fetchMenu(option.key, cancellationToken))
  );
};

Connector.prototype.fetchMenu = function fetchMenu(id, cancellationToken) {
  return this.portalApi
    .get({
      cancellationToken,
      route: `/menus/${encodeURIComponent(id)}`
    })
    .then(menu => {
      // Fetch all menu items
      return Promise.all(
        menu.items.map(itemId => {
          return this.fetchMenuItem(itemId, cancellationToken)
            .then(menuItem => {
              if (!menuItem.children) {
                return menuItem;
              }

              // Fetch sub-menu items
              return Promise.all(
                menuItem.children.map(childItemId => {
                  return this.fetchMenuItem(
                    childItemId,
                    cancellationToken
                  ).catch(() => ({ id: itemId }));
                })
              ).then(children => ({ ...menuItem, children }));
            })
            .catch(() => ({ id: itemId }));
        })
      ).then(items => ({ ...menu, items }));
    });
};

Connector.prototype.fetchMenuItem = function fetchMenuItem(
  id,
  cancellationToken
) {
  return this.portalApi.get({
    cancellationToken,
    route: `/menuItems/${encodeURIComponent(id)}`
  });
};

/**
 * Checkouts and Orders
 */
Connector.prototype.fetchOrder = function fetchOrder(id, cancellationToken) {
  return this.importApi.get({
    cancellationToken,
    route: `/orders/${encodeURIComponent(id)}`
  });
};

Connector.prototype.fetchCheckout = function fetchCheckout(
  id,
  cancellationToken
) {
  return this.portalApi.get({
    cancellationToken,
    route: `/checkouts/${encodeURIComponent(id)}`
  });
};

Connector.prototype.fetchCheckouts = async function fetchCheckouts(
  screenId,
  fromDate,
  toDate,
  confirmedOnly = false,
  cancellationToken
) {
  const limit = 100;

  let offset = 0;
  let result = [];
  let hasMore = true;

  do {
    const params = {
      fromDate,
      toDate,
      limit,
      offset,
      skip: offset,
      confirmedOnly
    };

    if (screenId) {
      params.screenId = screenId;
    }

    const items = await this.portalApi.get({
      cancellationToken,
      params,
      route: "/checkouts"
    });

    result = [...result, ...items];
    hasMore = items.length === limit;
    offset = offset + items.length;
  } while (hasMore);

  return result;
};

Connector.prototype.updateCheckout = async function updateCheckout(
  id,
  data,
  cancellationToken
) {
  return this.portalApi.patch({
    cancellationToken,
    route: `/checkouts/${encodeURIComponent(id)}`,
    data
  });
};

Connector.prototype.updateOrder = async function updateOrder(
  id,
  data,
  cancellationToken
) {
  return this.importApi.patch({
    cancellationToken,
    route: `/orders/${encodeURIComponent(id)}`,
    data
  });
};

/**
 * Sessions
 */
Connector.prototype.fetchSessions = async function fetchSessions(
  customerId,
  screenIds,
  fromDate,
  toDate,
  skip = 0,
  limit = 100,
  cancellationToken
) {
  let batchOffset = skip;
  let result = [];
  let hasMore = true;

  do {
    const params = { fromDate, toDate, limit, skip: batchOffset };

    if (screenIds) {
      params.screenIds = screenIds;
    }

    if (customerId) {
      params.customerId = customerId;
    }

    const items = await this.insightsApi.get({
      cancellationToken,
      params,
      route: "/sessions",
      cacheMaxAge: 60000
    });

    result = [...result, ...items];
    hasMore = items.length === limit;
    batchOffset = batchOffset + items.length;
  } while (hasMore && result.length < limit);

  return result.map(session => this.transformSession(session));
};

Connector.prototype.fetchSession = async function fetchSession(
  sessionId,
  cancellationToken
) {
  const session = await this.insightsApi.get({
    cancellationToken,
    route: `/sessions/${sessionId}`,
    cacheMaxAge: 3600000
  });

  return this.transformSession(session);
};

Connector.prototype.transformSession = function transformSession(session) {
  const timestamp = parseDate(session.timestamp);
  return { ...session, timestamp };
};

Connector.prototype.fetchScreenEvents = async function fetchScreenEvents(
  sessionId,
  cancellationToken
) {
  const events = await this.insightsApi.get({
    cancellationToken,
    route: `/sessions/${sessionId}/events`,
    cacheMaxAge: 3600000
  });

  return events.map(event => ({
    ...event,
    timestamp: parseDate(event.timestamp),
    localTimestamp: parseDate(event.localTimestamp),
    raw: JSON.stringify(event, null, 2)
  }));
};

Connector.prototype.fetchSessionStats = function fetchSessionStats(
  customerId,
  screenIds,
  fromDate,
  toDate,
  interval,
  isDemo = false,
  cancellationToken
) {
  const params = { fromDate, toDate, isDemo };

  if (interval) {
    params.interval = interval;
  }

  if (screenIds) {
    params.screenIds = screenIds;
  }

  if (customerId) {
    params.customerId = customerId;
  }

  return this.insightsApi.get({
    cancellationToken,
    params,
    route: `/stats/sessions`
  });
};

Connector.prototype.fetchHeartbeatStats = function fetchHeartbeatStats(
  customerId,
  screenIds,
  fromDate,
  toDate,
  isDemo = false,
  cancellationToken
) {
  const params = { fromDate, toDate, isDemo };

  if (screenIds) {
    params.screenIds = screenIds;
  }

  if (customerId) {
    params.customerId = customerId;
  }

  return this.insightsApi.get({
    cancellationToken,
    params,
    route: `/stats/heartbeats`
  });
};

Connector.prototype.fetchCheckoutStats = function fetchCheckoutStats(
  customerId,
  screenIds,
  fromDate,
  toDate,
  interval,
  isDemo = false,
  cancellationToken
) {
  const params = { fromDate, toDate, isDemo };

  if (interval) {
    params.interval = interval;
  }

  if (screenIds) {
    params.screenIds = screenIds;
  }

  if (customerId) {
    params.customerId = customerId;
  }

  return this.insightsApi.get({
    cancellationToken,
    params,
    route: `/stats/checkouts`
  });
};

Connector.prototype.fetchProductStats = function fetchProductStats(
  customerId,
  screenIds,
  fromDate,
  toDate,
  isDemo = false,
  cancellationToken
) {
  const params = { fromDate, toDate, isDemo };

  if (screenIds) {
    params.screenIds = screenIds;
  }

  if (customerId) {
    params.customerId = customerId;
  }

  return this.insightsApi.get({
    cancellationToken,
    params,
    route: `/stats/products`
  });
};

Connector.prototype.fetchTopItems = function fetchTopItems(
  customerId,
  screenIds,
  fromDate,
  toDate,
  limit,
  cancellationToken
) {
  const params = { fromDate, toDate, limit };

  if (screenIds) {
    params.screenIds = screenIds;
  }

  if (customerId) {
    params.customerId = customerId;
  }

  return this.insightsApi.get({
    cancellationToken,
    params,
    route: `/items`
  });
};

/**
 * Heartbeats
 */
Connector.prototype.fetchLatestHeartbeats = async function fetchLatestHeartbeats(
  customerId,
  screenIds,
  isDemo = false,
  cancellationToken
) {
  const params = { isDemo };

  if (screenIds) {
    params.screenIds = screenIds;
  }

  if (customerId) {
    params.customerId = customerId;
  }

  const heartbeats = await this.insightsApi.get({
    cancellationToken,
    params,
    route: "/heartbeats",
    cacheMaxAge: 5000
  });

  return heartbeats;
};

Connector.prototype.fetchErrors = function fetchErrors(
  fromDate,
  toDate,
  cancellationToken
) {
  return this.insightsApi.get({
    cancellationToken,
    route: "/errors",
    params: { fromDate, toDate },
    cacheMaxAge: 60000
  });
};

Connector.prototype.fetchProducts = async function fetchProducts(
  articleNumber,
  cancellationToken
) {
  return this.importApi.get({
    cancellationToken,
    route: "/products",
    params: { articleNumber }
  });
};

/**
 * Mail
 */
Connector.prototype.fetchContacts = function fetchContacts(cancellationToken) {
  return this.mailApi.get({
    cancellationToken,
    route: "/contacts"
  });
};

Connector.prototype.sendEmail = function sendEmail(
  email,
  subject,
  headline,
  message,
  actionButtonLabel = "Learn more",
  actionButtonUrl = "https://www.touchtech.com",
  cancellationToken
) {
  return this.mailApi.post({
    cancellationToken,
    route: `/send`,
    data: {
      email,
      subject,
      headline,
      message,
      actionButtonLabel,
      actionButtonUrl
    }
  });
};

/**
 * Request
 */
Connector.prototype.getCancellationTokenSource = function getCancellationTokenSource() {
  const { token, cancel } = axios.CancelToken.source();
  return { cancel, cancellationToken: token };
};

function parseDate(isoString) {
  return isoString ? new Date(isoString) : null;
}
