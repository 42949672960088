import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import { CheckBox, Box } from "grommet";

import ensureStore from "../store";

class DemoToggle extends React.Component {
  constructor(props) {
    super(props);

    this.handleDemoToggleChange = this.handleDemoToggleChange.bind(this);
    this.store = ensureStore();
  }

  handleDemoToggleChange(e) {
    this.store.setShowDemoScreens(e.target.checked);

    // Signal change to parent component
    if (this.props.onChange) {
      this.props.onChange(e.target.checked);
    }
  }

  render() {
    return (
      <Box margin={{ top: "small" }}>
        <CheckBox
          label="Demo Screens"
          checked={this.store.showDemoScreens}
          onChange={this.handleDemoToggleChange}
          reverse
          toggle
        />
      </Box>
    );
  }
}

DemoToggle.propTypes = {
  onChange: PropTypes.func
};

export default observer(DemoToggle);
