import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Accordion } from "grommet";

import GroupedSelectorGroup from "./GroupedSelectorGroup";

class GroupedSelector extends React.Component {
  constructor(props) {
    super(props);

    this.handleItemsSelected = this.handleItemsSelected.bind(this);
    this.handleItemsUnselected = this.handleItemsUnselected.bind(this);
  }

  handleItemsSelected(items) {
    this.props.onSelect(items);
  }

  handleItemsUnselected(items) {
    this.props.onUnselect(items);
  }

  render() {
    const {
      groups,
      groupIdKey = "id",
      groupLabelKey = "label",
      itemsKey = "items",
      itemIdKey = "id",
      itemLabelKey = "label",
      itemFallbackLabelKey = null,
      itemContentKey = "content",
      selectedItems = []
    } = this.props;

    if (!groups || !groups.length) {
      return null;
    }

    const groupItems = groups.map(group => {
      return (
        <GroupedSelectorGroup
          key={group[groupIdKey]}
          group={group}
          groupIdKey={groupIdKey}
          groupLabelKey={groupLabelKey}
          itemsKey={itemsKey}
          itemIdKey={itemIdKey}
          itemLabelKey={itemLabelKey}
          itemFallbackLabelKey={itemFallbackLabelKey}
          itemContentKey={itemContentKey}
          selectedItems={selectedItems}
          onSelect={this.handleItemsSelected}
          onUnselect={this.handleItemsUnselected}
        />
      );
    });

    return <Accordion>{groupItems}</Accordion>;
  }
}

GroupedSelector.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
  groupIdKey: PropTypes.string,
  groupLabelKey: PropTypes.string,
  itemsKey: PropTypes.string,
  itemIdKey: PropTypes.string,
  itemLabelKey: PropTypes.string,
  itemFallbackLabelKey: PropTypes.string,
  itemContentKey: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onUnselect: PropTypes.func.isRequired,
  selectedItems: PropTypes.object
};

export default observer(GroupedSelector);
