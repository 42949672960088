import { types, getRoot, getEnv, flow } from "mobx-state-tree";

import mapToList from "../../lib/mapToList";
import listToMap from "../../lib/listToMap";

const TermCount = types.optional(types.number, 0);
const TermCountMap = types.optional(types.map(TermCount), {});

/*
const Metric = types.model("Metric", {
  avg: types.maybeNull(types.number),
  count: types.maybeNull(types.number),
  max: types.maybeNull(types.number),
  min: types.maybeNull(types.number),
  sum: types.maybeNull(types.number)
});*/

const CheckoutAggregations = types.model("CheckoutAggregations", {
  count: types.optional(types.number, 0),
  paymentStatus: TermCountMap,
  orderStatus: TermCountMap,
  currencyCode: TermCountMap,
  deliveryCountryCode: TermCountMap,
  paymentMethod: TermCountMap,
  handlerCode: TermCountMap,
  totalPriceSEK: types.maybe(types.number),
  totalPriceEUR: types.maybe(types.number),
  totalPriceUSD: types.maybe(types.number)
});

const CheckoutStats = types
  .model("CheckoutStats", {
    id: types.maybe(types.string),
    count: types.optional(types.number, 0),
    confirmationRate: types.optional(types.number, 0),
    failureRate: types.optional(types.number, 0),
    ordered: types.optional(CheckoutAggregations, {}),
    confirmed: types.optional(CheckoutAggregations, {}),
    committed: types.optional(CheckoutAggregations, {}),
    failed: types.optional(CheckoutAggregations, {})
  })
  .views(self => ({
    get name() {
      const screen = getRoot(self).screens.items.get(self.id);
      return screen ? screen.name : self.id;
    }
  }));

const SessionAggregation = types.model("SessionAggregation", {
  count: types.optional(types.number, 0),
  didEndPrematurely: types.optional(types.number, 0),
  didUseRfidScanner: types.optional(types.number, 0),
  didSearch: types.optional(types.number, 0),
  didUseSocialSecurityLookup: types.optional(types.number, 0),
  didFilter: types.optional(types.number, 0),
  hasAbandonedBasket: types.optional(types.number, 0),
  didChangeCountry: types.optional(types.number, 0),
  didChangeLanguage: types.optional(types.number, 0),
  hasBasket: types.optional(types.number, 0),
  hasCheckout: types.optional(types.number, 0),
  didUseBarcodeScanner: types.optional(types.number, 0),
  selectedFilters: TermCountMap,
  selectedMenuItems: TermCountMap
});

const SessionStats = types
  .model("SessionStats", {
    id: types.maybe(types.string),
    count: types.optional(types.number, 0),
    all: types.optional(SessionAggregation, {}),
    hasCheckout: types.optional(SessionAggregation, {}),
    abandonedBasketRate: types.maybeNull(types.number),
    basketRate: types.maybeNull(types.number),
    confirmationRate: types.maybeNull(types.number),
    filterRate: types.maybeNull(types.number),
    searchRate: types.maybeNull(types.number)
  })
  .views(self => ({
    get name() {
      const screen = getRoot(self).screens.items.get(self.id);
      return screen ? screen.name : self.id;
    }
  }));

const HeartbeatStats = types.model("HeartbeatStats", {
  appVersion: TermCountMap,
  windowsVersion: TermCountMap,
  networkType: TermCountMap,
  displaySize: TermCountMap,
  countryCode: TermCountMap,
  screenCount: 0
});

const ProductStats = types.model("ProductStats", {
  interactedProducts: TermCountMap,
  interactedCategoryIds: TermCountMap,
  basketProducts: TermCountMap,
  basketCategoryIds: TermCountMap,
  notFoundArticleNumbers: TermCountMap
});

const SessionHistogramBucket = types.model("HistogramBucket", {
  weekOfYear: types.maybe(types.number),
  global: types.optional(SessionStats, {}),
  screens: types.optional(types.map(SessionStats), {})
});

export default types
  .model("Stats", {
    checkouts: types.optional(CheckoutStats, {}),
    screenCheckouts: types.optional(types.map(CheckoutStats), {}),
    staffCheckouts: types.optional(types.map(CheckoutStats), {}),
    sessions: types.optional(SessionStats, {}),
    screenSessions: types.optional(types.map(SessionStats), {}),
    products: types.optional(types.map(ProductStats), {}),
    heartbeats: types.optional(HeartbeatStats, {}),
    sessionHistogram: types.optional(types.array(SessionHistogramBucket), []),
    histogramInterval: "week"
  })
  .views(self => ({
    get isLoading() {
      return (
        getRoot(self).pendingActions.has("fetchSessionStats") ||
        getRoot(self).pendingActions.has("fetchHeartbeatStats") ||
        getRoot(self).pendingActions.has("fetchCheckoutStats") ||
        getRoot(self).pendingActions.has("fetchProductStats")
      );
    }
  }))
  .actions(self => ({
    reset() {
      self.checkouts = {};
      self.screenCheckouts = {};
      self.staffCheckouts = {};
      self.sessions = {};
      self.screenSessions = {};
      self.products = {};
    },
    fetchSessionStats(screenIds) {
      const { cancellationToken, cancel } = getEnv(
        self
      ).connector.getCancellationTokenSource();

      const action = flow(function* fetch() {
        const json = yield getEnv(self).connector.fetchSessionStats(
          getRoot(self).customerId,
          screenIds,
          getRoot(self).fromDate,
          getRoot(self).toDate,
          null,
          getRoot(self).showDemoScreens,
          cancellationToken
        );

        self.sessions = { ...json.global, name: "global" };
        self.screenSessions = json.screens;
      });

      return getRoot(self).runAction("fetchSessionStats", action, cancel);
    },
    fetchHeartbeatStats(screenIds) {
      const { cancellationToken, cancel } = getEnv(
        self
      ).connector.getCancellationTokenSource();

      const action = flow(function* fetch() {
        const json = yield getEnv(self).connector.fetchHeartbeatStats(
          getRoot(self).customerId,
          screenIds,
          getRoot(self).fromDate,
          getRoot(self).toDate,
          getRoot(self).showDemoScreens,
          cancellationToken
        );

        self.heartbeats = json;
      });

      return getRoot(self).runAction("fetchHeartbeatStats", action, cancel);
    },
    fetchCheckoutStats(screenIds) {
      const { cancellationToken, cancel } = getEnv(
        self
      ).connector.getCancellationTokenSource();

      const action = flow(function* fetch() {
        const json = yield getEnv(self).connector.fetchCheckoutStats(
          getRoot(self).customerId,
          screenIds,
          getRoot(self).fromDate,
          getRoot(self).toDate,
          null,
          getRoot(self).showDemoScreens,
          cancellationToken
        );

        self.checkouts = json.global;
        self.screenCheckouts = json.screens;
        self.staffCheckouts = json.staff;
      });

      return getRoot(self).runAction("fetchCheckoutStats", action, cancel);
    },
    fetchProductStats(screenIds) {
      const { cancellationToken, cancel } = getEnv(
        self
      ).connector.getCancellationTokenSource();

      const action = flow(function* fetch() {
        const json = yield getEnv(self).connector.fetchProductStats(
          getRoot(self).customerId,
          screenIds,
          getRoot(self).fromDate,
          getRoot(self).toDate,
          getRoot(self).showDemoScreens,
          cancellationToken
        );

        self.products = json;
      });

      return getRoot(self).runAction("fetchProductStats", action, cancel);
    },
    fetchHistogram(screenIds) {
      const { cancellationToken, cancel } = getEnv(
        self
      ).connector.getCancellationTokenSource();

      const action = flow(function* fetch() {
        const json = yield getEnv(self).connector.fetchSessionStats(
          getRoot(self).customerId,
          screenIds,
          getRoot(self).fromDate,
          getRoot(self).toDate,
          self.histogramInterval,
          getRoot(self).showDemoScreens,
          cancellationToken
        );

        self.sessionHistogram = json;
      });

      return getRoot(self).runAction("fetchStatsHistogram", action, cancel);
    },
    fetchExport(option) {
      switch (option) {
        case "screens":
          return self.fetchExportScreens();
        case "screens-basic":
          return self.fetchExportScreensBasic();
        case "screens-daily":
          return self.fetchExportScreensInterval("day");
        case "screens-weekly":
          return self.fetchExportScreensInterval("week");
        case "screens-monthly":
          return self.fetchExportScreensInterval("month");
        case "interacted-products":
          return self.fetchExportProducts("interactedProducts");
        case "interacted-categories":
          return self.fetchExportProducts("interactedCategoryIds");
        case "basket-products":
          return self.fetchExportProducts("basketProducts");
        case "basket-categories":
          return self.fetchExportProducts("basketCategoryIds");
        default:
          throw new Error(`Invalid export option ${option}`);
      }
    },
    async fetchExportScreens() {
      const [screens, sessions, checkouts] = await Promise.all([
        getEnv(self).connector.fetchScreens(),
        getEnv(self).connector.fetchSessionStats(
          getRoot(self).customerId,
          null,
          getRoot(self).fromDate,
          getRoot(self).toDate,
          null,
          getRoot(self).showDemoScreens
        ),
        getEnv(self).connector.fetchCheckoutStats(
          getRoot(self).customerId,
          null,
          getRoot(self).fromDate,
          getRoot(self).toDate,
          null,
          getRoot(self).showDemoScreens
        )
      ]);

      const screenMap = listToMap(screens, "id");
      const result = new Map();

      for (const [key, { all }] of Object.entries(sessions.screens)) {
        const { name, settings } = screenMap[key];

        const storeIdSetting = settings.storeId;
        const storeId = storeIdSetting ? storeIdSetting.value : null;

        result.set(key, {
          screenId: key,
          screenName: name,
          storeId,
          sessions: all
        });
      }

      for (const [key, { confirmed }] of Object.entries(checkouts.screens)) {
        result.set(key, { ...result.get(key), checkouts: confirmed });
      }

      return mapToList(result, true);
    },
    async fetchExportScreensBasic() {
      const [screens, sessions, checkouts] = await Promise.all([
        getEnv(self).connector.fetchScreens(),
        getEnv(self).connector.fetchSessionStats(
          getRoot(self).customerId,
          null,
          getRoot(self).fromDate,
          getRoot(self).toDate,
          null,
          getRoot(self).showDemoScreens
        ),
        getEnv(self).connector.fetchCheckoutStats(
          getRoot(self).customerId,
          null,
          getRoot(self).fromDate,
          getRoot(self).toDate,
          null,
          getRoot(self).showDemoScreens
        )
      ]);

      const screenMap = listToMap(screens, "id");
      const result = new Map();

      for (const [key, { all }] of Object.entries(sessions.screens)) {
        const { name, settings } = screenMap[key];

        const storeIdSetting = settings.storeId;
        const storeId = storeIdSetting ? storeIdSetting.value : null;

        result.set(key, {
          screenId: key,
          screenName: name,
          storeId,
          sessionCount: all.count,
          basketCount: all.hasBasket
        });
      }

      for (const [key, { confirmed }] of Object.entries(checkouts.screens)) {
        result.set(key, {
          ...result.get(key),
          checkoutCount: confirmed.count,
          checkoutsTotalPriceEUR: confirmed.totalPriceEUR,
          checkoutsTotalPriceSEK: confirmed.totalPriceSEK,
          checkoutsTotalPriceUSD: confirmed.totalPriceUSD
        });
      }

      return mapToList(result, true);
    },
    async fetchExportScreensInterval(interval = "day") {
      const [screens, buckets] = await Promise.all([
        getEnv(self).connector.fetchScreens(),
        getEnv(self).connector.fetchSessionStats(
          getRoot(self).customerId,
          null,
          getRoot(self).fromDate,
          getRoot(self).toDate,
          interval,
          getRoot(self).showDemoScreens
        )
      ]);

      const screenMap = listToMap(screens, "id");
      const result = new Map();

      for (const bucket of buckets) {
        for (const [screenId, { all }] of Object.entries(bucket.screens)) {
          const { name, settings } = screenMap[screenId];

          const storeIdSetting = settings.storeId;
          const storeId = storeIdSetting ? storeIdSetting.value : null;

          let { fromDate, toDate } = bucket;

          if (fromDate < getRoot(self).fromDate) {
            fromDate = getRoot(self).fromDate;
          }

          if (toDate > getRoot(self).toDate) {
            toDate = getRoot(self).toDate;
          }

          const key = `${fromDate}-${toDate}-${screenId}`;

          result.set(key, {
            fromDate,
            toDate,
            screenId,
            screenName: name,
            storeId,
            sessions: all
          });
        }
      }

      return mapToList(result, true);
    },
    async fetchExportProducts(statsKey) {
      const stats = await getEnv(self).connector.fetchProductStats(
        getRoot(self).customerId,
        null,
        getRoot(self).fromDate,
        getRoot(self).toDate,
        getRoot(self).showDemoScreens
      );

      const result = new Map();

      for (const [key, value] of Object.entries(stats[statsKey])) {
        result.set(key, { key, count: value });
      }

      return mapToList(result, true);
    }
  }));
