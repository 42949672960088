import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Button as GrommetButton } from "grommet";

class Button extends React.Component {
  constructor(props) {
    super(props);

    this.handlePress = this.handlePress.bind(this);
  }

  handlePress() {
    if (!this.props.onPress) {
      return;
    }

    this.props.onPress();
  }

  render() {
    const {
      label,
      type,
      icon,
      margin,
      alignSelf,
      isPrimary,
      isDisabled,
      isLoading,
      isReverse,
      isPlain,
      onPress
    } = this.props;

    const isSubmitButton = type === "submit";
    const canPress = onPress || isSubmitButton;

    return (
      <GrommetButton
        label={label}
        primary={isPrimary}
        icon={icon}
        type={type}
        margin={margin}
        reverse={isReverse}
        hoverIndicator={true}
        disabled={isDisabled || isLoading || !canPress}
        alignSelf={alignSelf}
        onClick={this.handlePress}
        plain={isPlain}
      />
    );
  }
}

Button.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.object,
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  alignSelf: PropTypes.string,
  isDisabled: PropTypes.bool,
  isPrimary: PropTypes.bool,
  isLoading: PropTypes.bool,
  isReverse: PropTypes.bool,
  isPlain: PropTypes.bool,
  onPress: PropTypes.func
};

export default observer(Button);
