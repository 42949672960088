import React from "react";
import { observer } from "mobx-react";
import { Box, Heading, Text } from "grommet";
import { LinkNext } from "grommet-icons";

import ensureStore from "../store";
import exportCsv from "../lib/exportCsv";

import SideBar from "./SideBar";
import DemoToggle from "./DemoToggle";
import ListHeader from "../components/ListHeader";
import SearchInput from "../components/SearchInput";
import DateRangePicker from "../components/DateRangePicker";
import CheckoutSummary from "../components/Checkouts/CheckoutSummary";
import OptionSelector from "../components/OptionSelector";
import Button from "../components/Buttons/Button";
import Modal from "../components/Modal";

class Checkouts extends React.Component {
  constructor(props) {
    super(props);

    this.handleShowExportModal = this.handleShowExportModal.bind(this);
    this.handleDismissExportModal = this.handleDismissExportModal.bind(this);
    this.handleExportOptionChange = this.handleExportOptionChange.bind(this);
    this.handleExport = this.handleExport.bind(this);

    this.handleSelectMetric = this.handleSelectMetric.bind(this);
    this.handleDemoToggleChange = this.handleDemoToggleChange.bind(this);
    this.handleFromDateChange = this.handleFromDateChange.bind(this);
    this.handleToDateChange = this.handleToDateChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);

    this.state = { showExportModal: false, exportOption: "all" };
    this.store = ensureStore();
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    this.fetchData();
  }

  fetchData() {
    setImmediate(() => {
      this.store.screens.fetch();
      this.store.checkouts.fetch();
      this.store.stats.fetchCheckoutStats();
    });
  }

  handleShowExportModal() {
    this.setState({ showExportModal: true });
  }

  handleDismissExportModal() {
    this.setState({ showExportModal: false });
  }

  handleExportOptionChange(exportOption) {
    this.setState({ exportOption });
  }

  async handleExport() {
    const { exportOption } = this.state;

    const fileName = `checkouts-${exportOption}.csv`;
    const data = await this.store.checkouts.fetchExport(exportOption);
    await exportCsv(fileName, data);
  }

  handleDemoToggleChange() {
    this.fetchData();
  }

  handleSelectMetric(sortKey, sortDescending) {
    //...
  }

  handleSearch(checkoutId) {
    this.props.history.push(`/customer/checkouts/${checkoutId}`);
  }

  handleFromDateChange(fromDate) {
    this.store.setFromDate(fromDate);
    this.fetchData();
  }

  handleToDateChange(toDate) {
    this.store.setToDate(toDate);
    this.fetchData();
  }

  renderCheckoutStats() {
    const { stats } = this.store;

    return (
      <Box
        background="white"
        direction="row"
        pad={{ vertical: "small", horizontal: "medium" }}
        justify="between"
        gap="small"
      >
        <Box border={{ side: "right" }} fill="horizontal">
          <Text size="small" style={{ textTransform: "uppercase" }}>
            Sessions
          </Text>
          <Heading level={3}>{stats.sessions.all.count}</Heading>
        </Box>

        <Box border={{ side: "right" }} fill="horizontal">
          <Text size="small" style={{ textTransform: "uppercase" }}>
            Baskets
          </Text>
          <Heading level={3}>{stats.sessions.all.hasBasket}</Heading>
        </Box>

        <Box border={{ side: "right" }} fill="horizontal">
          <Text size="small" style={{ textTransform: "uppercase" }}>
            Abandoned Baskets
          </Text>
          <Heading level={3}>
            {stats.sessions.abandonedBasketRate * 100}%
          </Heading>
        </Box>

        <Box border={{ side: "right" }} fill="horizontal">
          <Text size="small" style={{ textTransform: "uppercase" }}>
            Checkouts
          </Text>
          <Heading level={3}>{stats.checkouts.confirmed.count}</Heading>
        </Box>

        <Box fill="horizontal">
          <Text size="small" style={{ textTransform: "uppercase" }}>
            Failed Checkouts
          </Text>
          <Heading level={3}>{stats.checkouts.failed.count}</Heading>
        </Box>
      </Box>
    );
  }

  renderCheckouts(checkouts) {
    return checkouts.map(checkout => (
      <CheckoutSummary key={checkout.id} checkout={checkout} />
    ));
  }

  render() {
    const { stats, checkouts, screens } = this.store;
    const { sortedItems } = checkouts;

    const isLoading = checkouts.isLoading || screens.isLoading;

    return (
      <React.Fragment>
        <SideBar history={this.props.history} location={this.props.location} />
        <Box
          direction="column"
          background={{ color: "light-2" }}
          pad={{ horizontal: "xlarge", vertical: "medium" }}
        >
          <Box
            direction="row-responsive"
            align="center"
            justify="between"
            margin={{ bottom: "xsmall" }}
          >
            <SearchInput
              placeholder="Go to checkout id..."
              onSubmit={this.handleSearch}
            />
            <Box direction="row-responsive" gap="medium">
              <DateRangePicker
                value={this.store.fromDate}
                onDateChange={this.handleFromDateChange}
                maxDate={this.store.toDate}
              />
              <DateRangePicker
                value={this.store.toDate}
                onDateChange={this.handleToDateChange}
                minDate={this.store.fromDate}
              />
            </Box>
          </Box>
          <Box
            pad={{ top: "medium", bottom: "xlarge" }}
            alignSelf="end"
            align="end"
          >
          <Box direction="row">
          <Heading
              level={1}
              size="large"
              style={{ fontWeight: "600", letterSpacing: "-0.025em" }}
            >
              {Math.round(stats.checkouts.confirmed.totalPriceSEK) || 0}
            </Heading>
            <Heading
              level={1}
              size="large"
              style={{ fontWeight: "300", letterSpacing: "-0.033em" }}
            >{`\u00a0SEK`}</Heading>
          </Box>
            <DemoToggle onChange={this.handleDemoToggleChange}/>
          </Box>
          <ListHeader
            isLoading={isLoading}
            onSelectMetric={this.handleSelectMetric}
            metricHeaders={[
              { label: "Staff", key: "employee" },
              { label: "Ordered Quantity", sortKey: "orderedQuantity", sortDescending: true },
              { label: "Shipped Quantity", sortKey: "shippedQuantity", sortDescending: true },
              { label: "Value", sortKey: "totalPrice", sortDescending: true }
            ]}
          >
            <Button
              label="Export Data"
              onPress={this.handleShowExportModal}
              icon={<LinkNext />}
              isReverse
              isPrimary
            />
          </ListHeader>
          <Box gap="hair">{this.renderCheckouts(sortedItems)}</Box>
        </Box>
        <Modal
          heading="Export"
          isActive={this.state.showExportModal}
          onDismiss={this.handleDismissExportModal}
          onConfirm={this.handleExport}
          canConfirm={!isLoading}
          confirmLabel="Export CSV"
          title="Create customer"
        >
          <OptionSelector
            value={this.state.exportOption}
            options={[{ label: "All checkouts", value: "all-checkouts" }]}
            onChange={this.handleExportOptionChange}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

export default observer(Checkouts);
