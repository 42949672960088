import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { AccordionPanel, CheckBox, Heading, Box } from "grommet";

import GroupedSelectorItem from "./GroupedSelectorItem";

class GroupedSelectorGroup extends React.Component {
  constructor(props) {
    super(props);

    this.handleItemSelected = this.handleItemSelected.bind(this);
    this.handleItemUnselected = this.handleItemUnselected.bind(this);

    this.handleGroupSelectionChanged = this.handleGroupSelectionChanged.bind(
      this
    );
  }

  handleItemSelected(item) {
    this.props.onSelect([item]);
  }

  handleItemUnselected(item) {
    this.props.onUnselect([item]);
  }

  handleGroupSelectionChanged(e) {
    const { group, itemsKey } = this.props;

    if (e.target.checked) {
      this.props.onSelect(group[itemsKey]);
    } else {
      this.props.onUnselect(group[itemsKey]);
    }
  }

  render() {
    const {
      group,
      groupIdKey,
      groupLabelKey,
      itemsKey,
      itemIdKey,
      selectedItems,
      rowHeight = 64,
    } = this.props;

    if (!group) {
      return null;
    }

    const items = group[itemsKey];

    if (!items || !items.length) {
      return null;
    }

    const groupContactItems = items.map(item => {
      return (
        <GroupedSelectorItem
          key={item[itemIdKey]}
          item={item}
          itemIdKey={itemIdKey}
          itemLabelKey={this.props.itemLabelKey}
          itemFallbackLabelKey={this.props.itemFallbackLabelKey}
          itemContentKey={this.props.itemContentKey}
          selectedItems={selectedItems}
          onSelect={this.handleItemSelected}
          onUnselect={this.handleItemUnselected}
        />
      );
    });

    const selectedGroupItemCount = items.reduce((count, item) => {
      if (selectedItems.has(item.id)) {
        count += 1;
      }

      return count;
    }, 0);

    const isChecked = selectedGroupItemCount === items.length;
    const isIndeterminate = !isChecked && selectedGroupItemCount > 0;

    return (
      <Box direction="row" align="start">
        <Box
          justify="center"
          pad={{ left: "large" }}
          background="white"
          height={`${rowHeight}px`}
        >
          <CheckBox
            checked={isChecked}
            indeterminate={isIndeterminate}
            onChange={this.handleGroupSelectionChanged}
          />
        </Box>
        <Box fill={true}>
          <AccordionPanel
            key={group[groupIdKey]}
            background="white"
            label={
              <Box
                height={`${rowHeight}px`}
                background="white"
                pad={{ horizontal: "large" }}
                direction="row"
                align="center"
                gap="small"
              >
                <Box direction="column" gap="none">
                  <Heading
                    level={2}
                    size="small"
                    style={{
                      textTransform: "none",
                      lineHeight: "1em",
                      letterSpacing: "0em",
                    }}
                  >
                    {group[groupLabelKey]}
                  </Heading>
                </Box>
              </Box>
            }
          >
            <Box gap="hair">{groupContactItems}</Box>
          </AccordionPanel>
        </Box>
      </Box>
    );
  }
}

GroupedSelectorGroup.propTypes = {
  group: PropTypes.object.isRequired,
  groupIdKey: PropTypes.string,
  groupLabelKey: PropTypes.string,
  itemsKey: PropTypes.string,
  itemIdKey: PropTypes.string,
  itemLabelKey: PropTypes.string,
  itemFallbackLabelKey: PropTypes.string,
  itemContentKey: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onUnselect: PropTypes.func.isRequired,
  selectedItems: PropTypes.object,
};

export default observer(GroupedSelectorGroup);
