import React from "react";
import { observer } from "mobx-react";
import { Box, Heading, Text } from "grommet";
import { LinkNext } from "grommet-icons";

import ensureStore from "../store";
import exportCsv from "../lib/exportCsv";

import SideBar from "./SideBar";
import DemoToggle from "./DemoToggle";
import ListHeader from "../components/ListHeader";
import DateRangePicker from "../components/DateRangePicker";
import Button from "../components/Buttons/Button";
import Screens from "../components/Insights/Screens";
import ChartCard from "../components/Charts/ChartCard";
import PieChart from "../components/Charts/PieChart";
import BarChart from "../components/Charts/BarChart";
import HeatmapChart from "../components/Charts/HeatmapChart";
import OptionSelector from "../components/OptionSelector";
import Modal from "../components/Modal";

import mapToList from "../lib/mapToList";

class Insights extends React.Component {
  constructor(props) {
    super(props);

    this.handleShowExportModal = this.handleShowExportModal.bind(this);
    this.handleDismissExportModal = this.handleDismissExportModal.bind(this);
    this.handleExportOptionChange = this.handleExportOptionChange.bind(this);
    this.handleExport = this.handleExport.bind(this);

    this.handleSelectMetric = this.handleSelectMetric.bind(this);
    this.handleDemoToggleChange = this.handleDemoToggleChange.bind(this);
    this.handleFromDateChange = this.handleFromDateChange.bind(this);
    this.handleToDateChange = this.handleToDateChange.bind(this);

    this.state = { showExportModal: false, exportOption: "screens" };
    this.refreshIntervalHandle = null;

    this.store = ensureStore();
  }

  componentDidMount() {
    this.fetchData();

    this.clearRefreshInterval();

    this.refreshIntervalHandle = setInterval(
      () => this.store.screens.fetchLatestHeartbeats(),
      60000
    );
  }

  componentWillReceiveProps(nextProps) {
    //this.fetchData();
  }

  componentWillUnmount() {
    this.clearRefreshInterval();
  }

  clearRefreshInterval() {
    if (this.refreshIntervalHandle) {
      clearInterval(this.refreshIntervalHandle);
    }
  }

  fetchData() {
    setImmediate(() => {
      this.store.screens.fetch();
      this.store.stats.fetchSessionStats();
      this.store.stats.fetchHistogram();
      this.store.stats.fetchCheckoutStats();
      this.store.stats.fetchHeartbeatStats();
      //this.store.stats.fetchProductStats();
      this.store.screens.fetchLatestHeartbeats();
    });
  }

  handleShowExportModal() {
    this.setState({ showExportModal: true });
  }

  handleDismissExportModal() {
    this.setState({ showExportModal: false });
  }

  handleExportOptionChange(exportOption) {
    this.setState({ exportOption });
  }

  async handleExport() {
    const { exportOption } = this.state;

    const fileName = `insights-${exportOption}.csv`;
    const data = await this.store.stats.fetchExport(exportOption);
    await exportCsv(fileName, data);
  }

  handleSelectMetric(sortKey, sortDescending) {
    this.store.screens.setSortKey(sortKey, sortDescending);
  }

  handleDemoToggleChange() {
    this.fetchData();
  }

  handleFromDateChange(fromDate) {
    this.store.setFromDate(fromDate);
    this.fetchData();
  }

  handleToDateChange(toDate) {
    this.store.setToDate(toDate);
    this.fetchData();
  }

  renderCheckoutStats() {
    const { stats } = this.store;

    return (
      <Box
        background="white"
        direction="row"
        pad={{ vertical: "small", horizontal: "medium" }}
        justify="between"
        gap="small"
      >
        <Box border={{ side: "right" }} fill="horizontal">
          <Text size="small" style={{ textTransform: "uppercase" }}>
            Sessions
          </Text>
          <Heading level={3}>{stats.sessions.all.count}</Heading>
        </Box>

        <Box border={{ side: "right" }} fill="horizontal">
          <Text size="small" style={{ textTransform: "uppercase" }}>
            Baskets
          </Text>
          <Heading level={3}>{stats.sessions.all.hasBasket}</Heading>
        </Box>

        <Box border={{ side: "right" }} fill="horizontal">
          <Text size="small" style={{ textTransform: "uppercase" }}>
            Abandoned Baskets
          </Text>
          <Heading level={3}>
            {Math.round(stats.sessions.abandonedBasketRate * 100)}%
          </Heading>
        </Box>

        <Box border={{ side: "right" }} fill="horizontal">
          <Text size="small" style={{ textTransform: "uppercase" }}>
            Checkouts
          </Text>
          <Heading level={3}>{stats.checkouts.confirmed.count}</Heading>
        </Box>

        <Box fill="horizontal">
          <Text size="small" style={{ textTransform: "uppercase" }}>
            Failed Checkouts
          </Text>
          <Heading level={3}>{stats.checkouts.failed.count}</Heading>
        </Box>
      </Box>
    );
  }

  render() {
    const {
      sessions,
      checkouts,
      heartbeats,
      sessionHistogram,
      screenSessions,
      screenCheckouts,
      staffCheckouts,
      isLoading
    } = this.store.stats;

    const dataOptions = [
      {
        key: "order-status",
        label: "Order Status",
        data: mapToList(checkouts.ordered.orderStatus),
      },
      {
        key: "payment-status",
        label: "Payment Status",
        data: mapToList(checkouts.ordered.paymentStatus)
      },
      {
        key: "payment-method",
        label: "Payment Method",
        data: mapToList(checkouts.ordered.paymentMethod)
      },
      {
        key: "screen-checkouts",
        label: "Screen Checkouts",
        data: mapToList(screenCheckouts).map(({ value: { name, confirmed } }) => {
          return { key: name, value: confirmed.count };
        })
      },
      {
        key: "screen-sessions",
        label: "Screen Sessions",
        data: mapToList(screenSessions).map(({ value: { name, all } }) => {
          return { key: name, value: all.count };
        })
      },
      {
        key: "screen-checkout-value-eur",
        label: "Checkout Value - EUR",
        data: mapToList(screenCheckouts).map(({ value: { name, confirmed } }) => {
          return { key: name, value: Math.round(confirmed.totalPriceEUR) };
        })
      },
      {
        key: "screen-checkout-value-sek",
        label: "Checkout Value - SEK",
        data: mapToList(screenCheckouts).map(({ value: { name, confirmed } }) => {
          return { key: name, value: Math.round(confirmed.totalPriceSEK) };
        })
      },
      {
        key: "screen-checkout-value-usd",
        label: "Checkout Value - USD",
        data: mapToList(screenCheckouts).map(({ value: { name, confirmed } }) => {
          return { key: name, value: Math.round(confirmed.totalPriceUSD) };
        })
      },
      {
        key: "menu-categories",
        label: "Menu Categories",
        data: mapToList(sessions.all.selectedMenuItems)
      },
      {
        key: "currency",
        label: "Currency",
        data: mapToList(checkouts.confirmed.currencyCode)
      },
      {
        key: "delivery-country",
        label: "Delivery Country",
        data: mapToList(checkouts.confirmed.deliveryCountryCode)
      },
      {
        key: "country-code",
        label: "Country",
        data: mapToList(heartbeats.countryCode)
      },
      {
        key: "staff",
        label: "Staff",
        data: mapToList(staffCheckouts).map(({ key, value: { count } }) => {
          return { value: count, key };
        })
      }
    ];

    const histogramOptions = [
      {
        key: "weekly-sessions",
        label: "Weekly Sessions",
        data: sessionHistogram.map(({ weekOfYear, global }) => {
          const result = { weekOfYear: `${weekOfYear}`, global: global.all.count };

          return result;
        }),
        keys: ['global'],
        isDefault: true
      }
    ];

    const { sortKey, sortDescending, sortedItems } = this.store.screens;

    return (
      <React.Fragment>
        <SideBar history={this.props.history} location={this.props.location} />
        <Box
          direction="column"
          background={{ color: "light-2" }}
          pad={{ horizontal: "xlarge", vertical: "medium" }}
          gap="small"
        >
          <Box
            direction="row-responsive"
            align="center"
            justify="between"
            margin={{ bottom: "xsmall" }}
          >
            <Box />
            <Box
              direction="row-responsive"
              gap="medium"
              margin={{ top: "xsmall" }}
            >
              <DateRangePicker
                value={this.store.fromDate}
                onDateChange={this.handleFromDateChange}
                maxDate={this.store.toDate}
              />
              <DateRangePicker
                value={this.store.toDate}
                onDateChange={this.handleToDateChange}
                minDate={this.store.fromDate}
              />
            </Box>
          </Box>
          <Box
            direction="column"
            pad={{ top: "medium", bottom: "xlarge" }}
            alignSelf="end"
            align="end"
          >
            <Box direction="row">
              <Heading
                level={1}
                size="large"
                style={{ fontWeight: "600", letterSpacing: "-0.025em" }}
              >
                {Math.round(checkouts.confirmed.totalPriceSEK) || 0}
              </Heading>
              <Heading
                level={1}
                size="large"
                style={{ fontWeight: "300", letterSpacing: "-0.033em" }}
              >{`\u00a0SEK`}</Heading>
            </Box>
            <DemoToggle onChange={this.handleDemoToggleChange} />
          </Box>
          <ListHeader
            onSelectMetric={this.handleSelectMetric}
            isLoading={isLoading}
          >
            <Button
              label="Export Data"
              onPress={this.handleShowExportModal}
              icon={<LinkNext />}
              isReverse
              isPrimary
            />
          </ListHeader>

          {this.renderCheckoutStats()}

          <Box direction="row" gap="small">
            <ChartCard
              isLoading={isLoading}
              dataOptions={dataOptions}
              defaultOption="screen-checkouts"
              labelName="key"
            >
              <PieChart id="analytics-pie-chart-1" />
            </ChartCard>
            <ChartCard
              isLoading={isLoading}
              dataOptions={dataOptions}
              defaultOption="menu-categories"
              labelName="key"
            >
              <PieChart id="analytics-pie-chart-2" />
            </ChartCard>
          </Box>

          <Box>
            <ChartCard
              isLoading={isLoading}
              dataOptions={histogramOptions}
              defaultOption="weekly-sessions"
              labelName="key"
            >
              <BarChart id="analytics-histogram-chart" />
            </ChartCard>
          </Box>

          <ChartCard
            isLoading={isLoading}
            defaultOption="user-behavior-all"
            labelName="key"
            height={mapToList(screenSessions).length * 48 + 220}
            dataOptions={[
              {
                key: "user-behavior-all",
                label: "User behavior - All sessions",
                data: mapToList(screenSessions).map(({ value }) => {
                  const {
                    didFilter,
                    didSearch,
                    didUseRfidScanner,
                    didUseBarcodeScanner,
                    didUseSocialSecurityLookup,
                    didChangeCountry,
                    didChangeLanguage,
                    hasAbandonedBasket,
                    hasCheckout,
                    hasBasket,
                    count
                  } = value.all;

                  const item = {
                    key: value.name,
                    "Used Filter [%]": Math.round((didFilter / count) * 100),
                    "Used Search [%]": Math.round((didSearch / count) * 100),
                    "Rfid Scanner [%]": Math.round(
                      (didUseRfidScanner / count) * 100
                    ),
                    "Barcode Scanner [%]": Math.round(
                      (didUseBarcodeScanner / count) * 100
                    ),
                    "Address Lookup [%]": Math.round(
                      (didUseSocialSecurityLookup / count) * 100
                    ),
                    "Changed Country [%]": Math.round(
                      (didChangeCountry / count) * 100
                    ),
                    "Changed Language [%]": Math.round(
                      (didChangeLanguage / count) * 100
                    ),
                    "Added to Basket [%]": Math.round(
                      (hasBasket / count) * 100
                    ),
                    "Abandoned Basket [%]": Math.round(
                      (hasAbandonedBasket / count) * 100
                    ),
                    "Created Checkout [%]": Math.round(
                      (hasCheckout / count) * 100
                    )
                  };

                  return item;
                })
              },
              {
                key: "user-behavior-checkout",
                label: "User behavior - Created checkout",
                data: mapToList(screenSessions).map(({ value }) => {
                  const {
                    didFilter,
                    didSearch,
                    didUseRfidScanner,
                    didUseBarcodeScanner,
                    didUseSocialSecurityLookup,
                    didChangeCountry,
                    didChangeLanguage,
                    count
                  } = value.hasCheckout;

                  const item = {
                    key: value.name,
                    "Used Filter [%]": Math.round((didFilter / count) * 100),
                    "Used Search [%]": Math.round((didSearch / count) * 100),
                    "Rfid Scanner [%]": Math.round(
                      (didUseRfidScanner / count) * 100
                    ),
                    "Barcode Scanner [%]": Math.round(
                      (didUseBarcodeScanner / count) * 100
                    ),
                    "Address Lookup [%]": Math.round(
                      (didUseSocialSecurityLookup / count) * 100
                    ),
                    "Changed Country [%]": Math.round(
                      (didChangeCountry / count) * 100
                    ),
                    "Changed Language [%]": Math.round(
                      (didChangeLanguage / count) * 100
                    )
                  };

                  return item;
                })
              },
              {
                key: "selected-menu-items",
                label: "Menu Items",
                data: getNormalizedTermCount(
                  mapToList(screenSessions, true),
                  "all",
                  "selectedMenuItems"
                )
              },
              {
                key: "selected-filters",
                label: "Selected Filters",
                data: getNormalizedTermCount(
                  mapToList(screenSessions, true),
                  "all",
                  "selectedFilters"
                )
              }
            ]}
          >
            <HeatmapChart id="analytics-heatmap" />
          </ChartCard>
          <Box>
            <ListHeader
              onSelectMetric={this.handleSelectMetric}
              selectedMetric={sortKey}
              sortDescending={sortDescending}
              metricHeaders={[
                { label: "Sessions", sortKey: "sessionCount", sortDescending: true },
                { label: "Baskets", sortKey: "basketCount", sortDescending: true },
                { label: "Checkouts", sortKey: "checkoutCount", sortDescending: true },
                { label: "Failed Checkouts", sortKey: "failedCheckoutCount", sortDescending: true }
              ]}
            />
            <Screens
              screens={sortedItems}
              sortDescending={sortDescending}
              sortKey={sortKey}
            />
          </Box>
          <Modal
            heading="Export"
            isActive={this.state.showExportModal}
            onDismiss={this.handleDismissExportModal}
            onConfirm={this.handleExport}
            canConfirm={!isLoading}
            confirmLabel="Export CSV"
            title="Create customer"
          >
            <OptionSelector
              value={this.state.exportOption}
              onChange={this.handleExportOptionChange}
              options={[
                {
                  value: "screens",
                  label: "All Screens"
                },
                {
                  value: "screens-basic",
                  label: "All Screens - Basic"
                },
                {
                  value: "screens-daily",
                  label: "All Screens - Daily"
                },
                {
                  value: "screens-weekly",
                  label: "All Screens - Weekly"
                },
                {
                  value: "screens-monthly",
                  label: "All Screens - Monthly"
                },
                {
                  value: "interacted-products",
                  label: "Interacted Products"
                },
                {
                  value: "interacted-categories",
                  label: "Interacted Categories"
                },
                {
                  value: "basket-products",
                  label: "Basket Produts"
                },
                {
                  value: "basket-categories",
                  label: "Basket Categories"
                }
              ]}
            />
          </Modal>
        </Box>
      </React.Fragment>
    );
  }
}

function getNormalizedTermCount(itemOrItems, key, subKey) {
  const items = Array.isArray(itemOrItems) ? itemOrItems : [itemOrItems];

  return items.map(item => {
    const result = {
      key: item.name
    };

    let totalTermCount = 0;

    for (const [, termCount] of item[key][subKey]) {
      totalTermCount = totalTermCount + termCount;
    }

    for (const [term, termCount] of item[key][subKey]) {
      result[`${term} [%]`] =
        Math.round((termCount / totalTermCount) * 100) || 0;
    }

    return result;
  });
}

export default observer(Insights);
