import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
//import { FormDown } from "grommet-icons";

import { Box, Text, Button } from "grommet";

import LoadingIndicator from "../components/LoadingIndicator";

class ListHeader extends React.Component {
  constructor(props) {
    super(props);

    this.handleSelectMetric = this.handleSelectMetric.bind(this);
  }

  handleSelectMetric(sortKey, sortDescending = false) {
    if (this.props.onSelectMetric) {
      this.props.onSelectMetric(sortKey, sortDescending);
    }
  }

  renderMetricHeaders() {
    const { metricHeaders, selectedMetric } = this.props;

    if (!metricHeaders) {
      return null;
    }

    const cells = metricHeaders.map(({ label, sortKey, sortDescending }) => (
      <Box
        key={label}
        border="left"
        align="center"
        height="70px"
        background={sortKey === selectedMetric ? "light-3" : "white"}
        width="metric-cell"
        pad="none"
      >
        <Button
          onClick={() => this.handleSelectMetric(sortKey, sortDescending)}
          hoverIndicator={true}
          fill={true}
          plain
        >
          <Box justify="center" direction="row" align="center" fill={true}>
            <Text
              size="small"
              style={{ textTransform: "uppercase" }}
              textAlign="center"
            >
              {label}
            </Text>
          </Box>
        </Button>
      </Box>
    ));

    return (
      <Box
        className="sticky"
        justify="between"
        direction="row"
        background={{ color: "white" }}
        pad={{ right: "xsmall" }}
        elevation="xsmall"
        animation="fadeIn"
        fill="horizontal"
        height="70px"
      >
        <Button
          alignSelf="stretch"
          onClick={() => this.handleSelectMetric("sortName", false)}
          hoverIndicator={true}
          plain
        >
          <Box
            justify="start"
            direction="row"
            align="center"
            pad={{ horizontal: "medium" }}
          >
            <Text
              size="small"
              style={{ textTransform: "uppercase" }}
              textAlign="center"
            >
              Name
            </Text>
          </Box>
        </Button>
        <Box alignSelf="end" margin="none" direction="row">
          {cells}
        </Box>
      </Box>
    );
  }

  render() {
    const { children, isLoading = false } = this.props;

    return (
      <React.Fragment>
        <LoadingIndicator isLoading={isLoading} size={8} color="#333" />
        <Box
          direction="row"
          align="center"
          justify="between"
          margin={{ bottom: "medium" }}
        >
          <Box direction="row" align="center">
            {children}
          </Box>
        </Box>
        {this.renderMetricHeaders()}
      </React.Fragment>
    );
  }
}

ListHeader.propTypes = {
  children: PropTypes.any,
  history: PropTypes.any,
  location: PropTypes.any,
  isLoading: PropTypes.bool,
  onSelectMetric: PropTypes.func.isRequired,
  selectedMetric: PropTypes.string,
  sortDescending: PropTypes.bool
};

export default observer(ListHeader);
