import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Box, Text, Accordion, AccordionPanel } from "grommet";

import ListRow from "../ListRow";

// TODO: Move this to env
const kibanaUrl =
  "https://7b8d625c5b534279af84b2d03b977afe.europe-west3.gcp.cloud.es.io:9243/app/kibana#/doc/41e77310-3bb8-11ea-81a8-e363bab12cf5/logstash-*?id=";

class ErrorSummary extends React.Component {
  renderTerms(error, key, label) {
    console.log(error.customerName)
    if (!error[key] || !error[key].length) {
      return null;
    }

    const values = error[key].map(term => (
      <Text key={term.name}>{term.name}</Text>
    ));

    return (
      <Box direction="column" margin={{ top: "xsmall" }}>
        <Box
          background="brand"
          alignSelf="start"
          pad={{ horizontal: "xsmall", vertical: "hair" }}
          margin={{ bottom: "hair" }}
        >
          <Text
            size="small"
            style={{ textTransform: "uppercase", fontWeight: 800 }}
          >
            {label !== undefined ? label : key}
          </Text>
        </Box>

        <Box>{values}</Box>
      </Box>
    );
  }

  renderStats(error) {
    const timestamp = moment(error.timestamp);

    return (
      <Box direction="row">
        <Text
          size="small"
          style={{ textTransform: "uppercase", fontWeight: 800 }}
          color="brand"
        >
          {error.count} occurences , last {timestamp.fromNow()}
        </Text>
      </Box>
    );
  }

  renderException(error, maxLength = 150) {
    const { exception, sourceContext } = error;
    const items = [];

    if (exception && exception.source) {
      items.push(
        <Text className="exception-source" key="exception-source">
          {exception.source}
        </Text>
      );
    } else if (sourceContext && sourceContext.length) {
      items.push(
        <Text className="exception-source" key="exception-source">
          {sourceContext[0].name}
        </Text>
      );
    }

    if (exception && exception.message) {
      items.push(
        <Text className="exception-message" key="exception-message">
          {limitString(exception.message, maxLength)}
        </Text>
      );
    }

    return <Box>{items}</Box>;
  }

  renderGroupedErrors(error, maxLength = 130) {
    if (!error.errors) {
      return null;
    }

    const items = error.errors.map(groupedError => (
      <a
        href={`${kibanaUrl}${groupedError.id}`}
        key={groupedError.id}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Box margin={{ bottom: "medium" }}>
          {this.renderStats(groupedError)}
          <Box>
            <Text>{limitString(groupedError.key, maxLength)}</Text>
            {this.renderException(groupedError)}
          </Box>
        </Box>
      </a>
    ));

    return (
      <Accordion
        margin={{ top: "medium" }}
        animate={false}
        background="light-2"
      >
        <AccordionPanel
          label={`${error.errors.length} related errors`}
          pad="small"
        >
          <Box pad="medium" gap="small">
            {items}
          </Box>
        </AccordionPanel>
      </Accordion>
    );
  }

  render() {
    const { error } = this.props;

    if (!error) {
      return null;
    }

    return (
      <ListRow>
        <Box
          direction="column"
          alignSelf="stretch"
          pad={{ right: "large", bottom: "large" }}
          fill={true}
        >
          {this.renderStats(error)}
          <a
            href={`${kibanaUrl}${error.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Text
              style={{
                display: "block",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                fontWeight: 700,
                fontSize: "1.35em"
              }}
            >
              {error.key}
            </Text>
          </a>
          <Box
            className="error-header"
            direction="column"
            alignSelf="stretch"
            margin={{ top: "small" }}
            fill={true}
          >
            {this.renderException(error)}
            {this.renderGroupedErrors(error)}
          </Box>
          <Box className="terms" gap="small" margin={{ top: "medium" }}>
            {this.renderTerms(error, "customerName", "Customers")}
            {this.renderTerms(error, "screenId", "Screens")}
            {this.renderTerms(error, "kaseyaScreenName", "Screens (Kaseya)")}
            {this.renderTerms(error, "clientId", "Client")}
            {this.renderTerms(error, "sourceContext", "Source")}
            {this.renderTerms(error, "requestPath", "Request Path")}
            {this.renderTerms(error, "machineName", "Machine Name")}
            {this.renderTerms(error, "locationHost", "Host")}
            {this.renderTerms(error, "locationPath", "Path")}
            {this.renderTerms(error, "locationSearch", "Search")}
            {this.renderTerms(error, "email", "Email")}
          </Box>
        </Box>
      </ListRow>
    );
  }
}

ErrorSummary.propTypes = {
  error: PropTypes.object
};

function limitString(str, maxLength) {
  return str.length > maxLength ? `${str.substring(0, maxLength)} ...` : str;
}

export default observer(ErrorSummary);
