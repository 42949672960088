import React from "react";
import { observer } from "mobx-react";
import { Box, Heading, Layer, TextInput, FormField } from "grommet";
import { Add } from "grommet-icons";

import ensureStore from "../store";

import SideBar from "./SideBar";
import Modal from "../components/Modal";
import Button from "../components/Buttons/Button";
import LoadingIndicator from "../components/LoadingIndicator";
import CustomerSummary from "../components/Customers/CustomerSummary";
import SearchInput from "../components/SearchInput";

class Admin extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showAddCustomerModal: false, customerName: "" };
    this.store = ensureStore();

    this.handleSearch = this.handleSearch.bind(this);
    this.handleCreateCustomer = this.handleCreateCustomer.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);

    this.handleShowAddCustomerModal = this.handleShowAddCustomerModal.bind(
      this
    );

    this.handleDismissAddCustomerModal = this.handleDismissAddCustomerModal.bind(
      this
    );
  }

  componentDidMount() {
    this.store.customers.fetch();
  }

  handleSearch(customerId) {
    this.props.history.push(`/admin/customers/${customerId}`);
  }

  handleShowAddCustomerModal() {
    this.setState({ showAddCustomerModal: true });
  }

  handleDismissAddCustomerModal() {
    this.setState({ showAddCustomerModal: false, customerName: "" });
  }

  async handleCreateCustomer() {
    await this.store.customers.create(this.state.customerName, true);
    this.setState({ customerName: "" });
  }

  handleNameChange(e) {
    this.setState({ customerName: e.target.value });
  }

  renderCustomers() {
    return this.store.customers.sortedItems.map(customer => {
      return <CustomerSummary key={customer.id} customer={customer} />;
    });
  }

  render() {
    return (
      <React.Fragment>
        <SideBar history={this.props.history} location={this.props.location} />
        <Box
          direction="column"
          background={{ color: "light-2" }}
          pad={{ horizontal: "xlarge", vertical: "medium" }}
        >
          <SearchInput
            placeholder="Go to customer Id..."
            onSubmit={this.handleSearch}
          />
          <Layer position="bottom" modal={false} margin={{ bottom: "small" }}>
            <Button
              label="Add Customer"
              icon={<Add />}
              onPress={this.handleShowAddCustomerModal}
              isDisabled={this.state.showAddCustomerModal}
              isReverse
              isPrimary
            />
          </Layer>
          <Box pad={{ top: "medium", bottom: "large" }} alignSelf="end">
            <Heading
              level={1}
              size="large"
              style={{ fontWeight: "300", letterSpacing: "-0.033em" }}
            >
              Customers
            </Heading>
          </Box>
          <Box margin={{ bottom: "medium" }}>
            <LoadingIndicator
              isLoading={this.store.customers.isLoading}
              size={8}
              color="#333"
            />
          </Box>
          <Box gap="hair">{this.renderCustomers()}</Box>
        </Box>
        <Modal
          heading="New Customer"
          isActive={this.state.showAddCustomerModal}
          onConfirm={this.handleCreateCustomer}
          onDismiss={this.handleDismissAddCustomerModal}
          canConfirm={this.state.customerName.length > 0}
          confirmLabel="Create"
          description={"Please enter the customer's name."}
          title="Create customer"
        >
          <FormField>
            <TextInput
              type="text"
              value={this.state.customerName}
              onChange={this.handleNameChange}
              placeholder="Name"
              width="xlarge"
            />
          </FormField>
        </Modal>
      </React.Fragment>
    );
  }
}

export default observer(Admin);
