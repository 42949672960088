import React from "react";
import { observer } from "mobx-react";
import { Redirect, Route } from "react-router-dom";

import ensureStore from "../store";
import Map from "../containers/Map";
import Customer from "../containers/Customer";
import Customers from "../containers/Customers";
import Monitoring from "../containers/Monitoring";
import Notifications from "../containers/Notifications";
import Errors from "../containers/Errors";
import Footer from "../containers/Footer";

class AdminPortal extends React.Component {
  constructor(props) {
    super(props);

    this.store = ensureStore(props.store);
  }

  render() {
    const { location: referrer } = this.props;

    if (!this.store.identity.isSignedIn) {
      return <Redirect to={{ pathname: "/", state: { referrer } }} />;
    }

    if (!this.store.identity.isAdmin) {
      return <Redirect to={{ pathname: "/customer", state: { referrer } }} />;
    }

    return (
      <div className="container">
        <Route exact path="/admin" component={Monitoring} />
        <Route exact path="/admin/map" component={Map} />
        <Route exact path="/admin/customers" component={Customers} />
        <Route exact path="/admin/customers/:customerId" component={Customer} />
        <Route exact path="/admin/notifications" component={Notifications} />
        <Route exact path="/admin/monitoring" component={Monitoring} />
        <Route exact path="/admin/monitoring/screens/:screenId" component={Monitoring} />
        <Route exact path="/admin/errors" component={Errors} />
        <Footer />
      </div>
    );
  }
}

export default observer(AdminPortal);
