import { types, getRoot, getEnv, flow } from "mobx-state-tree";

import mapToList from "../../lib/mapToList";
import sort from "../../lib/sort";

export const Session = types
  .model("Session", {
    id: types.identifier,
    timestamp: types.Date,
    eventCount: types.number,
    customerId: types.string,
    screenId: types.string,
    screenName: types.string,
    checkoutId: types.maybeNull(types.string),
    currencyCode: types.maybe(types.string),
    deliveryCountryCode: types.maybe(types.string),
    didEnd: types.boolean,
    didTimeout: types.boolean,
    didEndPrematurely: types.boolean,
    hasCheckout: types.boolean,
    checkoutState: types.maybe(types.string),
    orderStatus: types.maybe(types.string),
    paymentStatus: types.maybe(types.string),
    totalPrice: types.maybe(types.number),
    basketSkus: types.array(types.string),
    basketSkuCount: types.number,
    selectedFilters: types.array(types.string),
    selectedFilterCount: types.number,
    selectedItems: types.array(types.string),
    selectedItemCount: types.number,
    selectedMenuItems: types.array(types.string),
    selectedMenuItemCount: types.number,
    views: types.array(types.maybeNull(types.string)),
    searchTerms: types.array(types.string),
    searchTermCount: types.number,
    searchCount: types.number,
    didSearch: types.boolean,
    didFilter: types.boolean
  })
  .views(self => ({
    get events() {
      return getRoot(self).screenEvents.items.filter(
        event => event.sessionId === self.id
      );
    }
  }));

export default types
  .model("Sessions", {
    sortKey: "timestamp",
    items: types.optional(types.map(Session), {})
  })
  .views(self => ({
    get sortedItems() {
      return sort(mapToList(self.items, true), self.sortKey, true);
    },

    get countByScreen() {
      const screenMap = this.sortedItems.reduce((result, { screenId }) => {
        const oldValue = result.has(screenId) ? result.get(screenId).count : 0;

        result.set(screenId, {
          screenId,
          screenName: getRoot(self).screens.items.has(screenId)
            ? getRoot(self).screens.items.get(screenId).name
            : null,
          count: oldValue + 1
        });

        return result;
      }, new Map());

      return sort(mapToList(screenMap, true), "count", true);
    },

    get isLoading() {
      return getRoot(self).pendingActions.has("fetchSessions");
    }
  }))
  .actions(self => ({
    reset() {
      self.items = {};
    },
    fetchById(sessionId) {
      const { cancellationToken, cancel } = getEnv(
        self
      ).connector.getCancellationTokenSource();

      const action = flow(function* fetchSessionById() {
        const session = yield getEnv(self).connector.fetchSession(
          //getRoot(self).customerId,
          sessionId,
          cancellationToken
        );

        self.items.set(session.id, session);

        getRoot(self).screenEvents.fetchBySessionId(session.id);
      });

      return getRoot(self).runAction("fetchSessionById", action, cancel);
    },
    fetch(screenIds, skip, limit) {
      const { cancellationToken, cancel } = getEnv(
        self
      ).connector.getCancellationTokenSource();

      const action = flow(function* fetch() {
        const sessions = yield getEnv(self).connector.fetchSessions(
          getRoot(self).customerId,
          screenIds,
          getRoot(self).fromDate,
          getRoot(self).toDate,
          skip,
          limit,
          cancellationToken
        );

        sessions.forEach(session => self.items.set(session.id, session));
      });

      return getRoot(self).runAction("fetchSessions", action, cancel);
    }
  }));
