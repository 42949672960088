import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Box, Heading, Text } from "grommet";

import Metric from "../Metric";
import MetricRow from "../MetricRow";
import ListRow from "../ListRow";
import StatusIndicator from "../StatusIndicator";

class ScreenSummary extends React.Component {
  render() {
    const { screen } = this.props;

    if (!screen) {
      return null;
    }

    const {
      id,
      name,
      appVersion,
      countryCode,
      status,
      displaySize,
      isUserSessionActive,
    } = screen;

    return (
      <ListRow link={`/customer/screens/${id}`}>
        <Box direction="row" align="center">
        <Box margin={{ right: "medium" }}>
            <StatusIndicator
              status={status}
              hasActivity={isUserSessionActive}
            />
          </Box>
          <Box>
            <Heading level={2} size="medium">
              {name}
            </Heading>
            <Box direction="row" gap="xsmall" margin={{ top: "xsmall" }}>
              <Box
                background="light-3"
                pad={{ horizontal: "small" }}
                round="10px"
              >
                <Text size="small">
                  {appVersion ? `Vendo ${appVersion}` : null}
                </Text>
              </Box>
              <Box
                background="light-3"
                pad={{ horizontal: "small" }}
                round="10px"
              >
                <Text size="small">
                  {displaySize ? `${displaySize}"` : null}
                </Text>
              </Box>
              {countryCode ? (
                <Box
                  background="light-3"
                  pad={{ horizontal: "small" }}
                  round="10px"
                >
                  <Text size="small">{countryCode}</Text>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
        <MetricRow>
          <Metric value={screen.sessionCount} />
          <Metric value={screen.basketCount} />
          <Metric value={screen.checkoutCount} />
          <Metric value={screen.failedCheckoutCount} />
        </MetricRow>
      </ListRow>
    );
  }
}

ScreenSummary.propTypes = {
  screen: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default observer(ScreenSummary);
