import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { PulseLoader } from "react-spinners";
import { LinkNext } from "grommet-icons";
import { Box, Layer, Heading, Form } from "grommet";

import Button from "../components/Buttons/Button";

class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isConfirming: false, error: null };

    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  async handleConfirm() {
    this.setState({ isConfirming: true, error: null }, async () => {
      try {
        await this.props.onConfirm();
        await this.handleDismiss();
      } catch (err) {
        this.setState({ error: err.message });
      } finally {
        this.setState({ isConfirming: false });
      }
    });
  }

  async handleDismiss() {
    await this.props.onDismiss();
  }

  renderHeading() {
    if (!this.props.heading) {
      return null;
    }

    return (
      <Heading
        level={1}
        size="medium"
        style={{ fontWeight: "400", letterSpacing: "-0.033em" }}
        margin={{ bottom: "large" }}
      >
        {this.props.heading}
      </Heading>
    );
  }

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return (
      <Heading level={2} size="small" margin={{ bottom: "large", top: "0" }}>
        {this.state.error}
      </Heading>
    );
  }

  renderConfirmButton() {
    if (!this.props.onConfirm) {
      return null;
    }

    const icon = this.state.isConfirming ? (
      <PulseLoader size={6} color="#FFF" />
    ) : (
      <LinkNext />
    );

    return (
      <Button
        label={this.props.confirmLabel || "OK"}
        isPrimary={true}
        isDisabled={!this.props.canConfirm || this.state.isConfirming}
        isReverse={true}
        type="submit"
        icon={icon}
      />
    );
  }

  renderDismissButton() {
    if (!this.props.onDismiss) {
      return null;
    }

    return (
      <Button
        label={this.props.dismissLabel || "Cancel"}
        onPress={this.handleDismiss}
        type="reset"
      />
    );
  }

  render() {
    const { children, isActive, width, pad = "large" } = this.props;

    if (!isActive) {
      return null;
    }

    return (
      <Layer
        position="center"
        background="#FFF"
        onClickOutside={this.handleDismiss}
        onEsc={this.handleDismiss}
      >
        <Form onSubmit={this.handleConfirm}>
          <Box
            elevation="medium"
            pad={{
              top: "large",
              bottom: "large"
            }}
          >
            <Box
              pad={{
                horizontal: "large"
              }}
            >
              {this.renderHeading()}
              {this.renderError()}
            </Box>
            <Box
              width={width}
              margin={{ top: "large", bottom: "medium" }}
              pad={{
                horizontal: pad
              }}
            >
              {children}
            </Box>
            <Box
              direction="row-responsive"
              alignSelf="stretch"
              justify="between"
              gap="small"
              pad={{
                horizontal: "large"
              }}
            >
              {this.renderDismissButton()}
              {this.renderConfirmButton()}
            </Box>
          </Box>
        </Form>
      </Layer>
    );
  }
}

Modal.propTypes = {
  onDismiss: PropTypes.func,
  onConfirm: PropTypes.func,
  canConfirm: PropTypes.bool,
  heading: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  confirmLabel: PropTypes.string,
  dismissLabel: PropTypes.string,
  width: PropTypes.string
};

export default observer(Modal);
