import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Box } from "grommet";

import OptionSelector from "../OptionSelector";
import LoadingIndicator from "../LoadingIndicator";

class ChartCard extends React.Component {
  constructor(props) {
    super(props);

    this.handleDataOptionChange = this.handleDataOptionChange.bind(this);
    this.state = { selectedOption: this.props.defaultOption || null };
  }

  handleDataOptionChange(value) {
    this.setState({ selectedOption: value });
  }

  renderContent() {
    const { height = 490 } = this.props;

    const {
      children,
      dataOptions = [],
      valueName,
      keyName,
      labelName
    } = this.props;

    if (!this.state.selectedOption) {
      return null;
    }

    const dataOption = dataOptions.find(
      ({ key }) => this.state.selectedOption === key
    );

    if (!dataOption || !dataOption.data) {
      return null;
    }

    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, {
        data: dataOption.data,
        keys: dataOption.keys,
        valueName,
        keyName,
        labelName
      })
    );

    return (
      <Box
        pad={{ top: "small", bottom: "small" }}
        style={{
          height
        }}
      >
        {childrenWithProps}
      </Box>
    );
  }

  render() {
    const { dataOptions } = this.props;
    const { selectedOption } = this.state;

    if (!dataOptions) {
      return null;
    }

    return (
      <Box fill={true} background="white">
        <Box direction="row" margin={{ top: "medium", left: "medium" }}>
          <OptionSelector
            options={dataOptions.map(({ key, label }) => ({
              label,
              value: key
            }))}
            onChange={this.handleDataOptionChange}
            placeholder={this.props.placeholder}
            value={selectedOption}
          />
          <LoadingIndicator isLoading={this.props.isLoading} />
        </Box>
        {this.renderContent()}
      </Box>
    );
  }
}

ChartCard.propTypes = {
  children: PropTypes.any.isRequired,
  dataOptions: PropTypes.array.isRequired,
  keyName: PropTypes.string,
  valueName: PropTypes.string,
  labelName: PropTypes.string,
  isLoading: PropTypes.bool
};

export default observer(ChartCard);
