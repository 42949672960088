import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Box, Stack } from "grommet";
import { BounceLoader } from "react-spinners";

class StatusIndicator extends React.Component {
  render() {
    const { status, hasActivity } = this.props;

    if (!status) {
      return null;
    }

    return (
      <Stack anchor="center">
        <Box
          width="14px"
          height="14px"
          round="7px"
          alignSelf="center"
          margin="none"
          background={{ color: `status-${this.props.status}` }}
        />
        {hasActivity ? <BounceLoader size={24} color="#00c781"/> : null}
      </Stack>
    );
  }
}

StatusIndicator.propTypes = {
  status: PropTypes.string
};

export default observer(StatusIndicator);
