import { types, getRoot, getEnv, flow } from "mobx-state-tree";

import Screen from "./screen";
import Heartbeat from "./heartbeat";
import mapToList from "../../lib/mapToList";
import sort from "../../lib/sort";

export default types
  .model("Screens", {
    sortKey: "sortName",
    sortDescending: false,
    items: types.optional(types.map(Screen), {}),
    heartbeats: types.optional(types.map(Heartbeat), {}),
  })
  .views(self => ({
    get filteredItems() {
      const items = mapToList(self.items, true);
      const { showDemoScreens } = getRoot(self);

      return items.filter(({ isDemo }) => isDemo === showDemoScreens);
    },

    get filteredHeartbeats() {
      const heartbeats = mapToList(self.heartbeats, true);
      const { showDemoScreens } = getRoot(self);

      return heartbeats.filter(
        ({ screenIsDemo }) => screenIsDemo === showDemoScreens
      );
    },

    get sortedItems() {
      return sort(self.filteredItems, self.sortKey, self.sortDescending);
    },

    get sortedHeartbeats() {
      return sort(self.filteredHeartbeats, self.sortKey, self.sortDescending);
    },

    get isLoading() {
      return (
        getRoot(self).pendingActions.has("fetchScreens") ||
        getRoot(self).pendingActions.has("fetchLatestHeartbeats")
      );
    },

    get totalCount() {
      return self.sortedItems.length;
    },

    get onlineCount() {
      return self.sortedItems.filter(s => s.isOnline).length;
    },
    get totalHeartbeatCount() {
      return self.sortedHeartbeats.length;
    },

    get onlineHeartbeatCount() {
      return self.sortedHeartbeats.filter(s => s.isOnline).length;
    },
  }))
  .actions(self => ({
    reset() {
      self.items = {};
      self.heartbeats = {};
    },

    setSortKey(sortKey, sortDescending = false) {
      if (self.sortKey === sortKey && self.sortDescending === sortDescending) {
        self.sortDescending = !self.sortDescending;
      } else {
        self.sortKey = sortKey;
        self.sortDescending = sortDescending;
      }
    },

    /**
     * Fetches a screen by its ID
     */
    fetchById(id) {
      const { cancellationToken, cancel } = getEnv(
        self
      ).connector.getCancellationTokenSource();

      const action = flow(function* fetchById() {
        const screen = yield getEnv(self).connector.fetchScreen(
          id,
          cancellationToken
        );

        // Obfuscate screen if this is a Demo Portal
        const transformedScreen = getRoot(self).isDemoPortal
          ? getEnv(self).obfuscate.screen(screen)
          : screen;

        self.items.set(id, transformedScreen);
      });

      return getRoot(self).runAction(`fetchScreenById-${id}`, action, cancel);
    },
    /**
     * Fetches a list of screens
     */
    fetch() {
      const { cancellationToken, cancel } = getEnv(
        self
      ).connector.getCancellationTokenSource();

      const action = flow(function* fetch() {
        const screens = yield getEnv(self).connector.fetchScreens(
          0,
          100,
          cancellationToken
        );

        screens.forEach(screen => {
          // Obfuscate screen if this is a Demo Portal
          const transformedScreen = getRoot(self).isDemoPortal
            ? getEnv(self).obfuscate.screen(screen)
            : screen;
            
          self.items.set(screen.id, transformedScreen);
        });
      });

      return getRoot(self).runAction("fetchScreens", action, cancel);
    },
    /**
     * Fetches a list of screen heartbeats
     */
    fetchLatestHeartbeats() {
      const { cancellationToken, cancel } = getEnv(
        self
      ).connector.getCancellationTokenSource();

      const action = flow(function* fetch() {
        const heartbeats = yield getEnv(self).connector.fetchLatestHeartbeats(
          getRoot(self).customerId,
          null,
          getRoot(self).showDemoScreens,
          cancellationToken
        );

        heartbeats.forEach(heartbeat => {
          self.heartbeats.set(heartbeat.screenId, heartbeat);
        });
      });

      return getRoot(self).runAction("fetchLatestHeartbeats", action, cancel);
    },
  }));
