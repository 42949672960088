import React from "react";
import { observer } from "mobx-react";
import { Box, Heading } from "grommet";

import ensureStore from "../store";

import SideBar from "./SideBar";
import DemoToggle from "./DemoToggle";
import SearchInput from "../components/SearchInput";
import MenuSummary from "../components/Menus/MenuSummary";

class Menus extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuId: null
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.store = ensureStore();
  }

  componentDidMount() {
    this.fetchData();
  }

  handleSearch(menuId) {
    this.props.history.push(`/customer/menus/${menuId}`);
  }

  fetchData() {
    setImmediate(() => {
      this.store.menus.fetch();
      this.store.screens.fetch();
    });
  }

  renderMenus() {
    const { sortedItems } = this.store.menus;

    if (!sortedItems) {
      return null;
    }

    return sortedItems.map(menu => {
      return <MenuSummary key={menu.id} menu={menu}/>;
    });
  }

  render() {
    return (
      <React.Fragment>
        <SideBar
          history={this.props.history}
          location={this.props.location}
          onRefresh={this.handleRefresh}
        />
        <Box
          direction="column"
          background={{ color: "light-2" }}
          pad={{ horizontal: "xlarge", vertical: "medium" }}
          gap="small"
        >
          <SearchInput
            onSubmit={this.handleSearch}
            placeholder="Search for menu id..."
          />
          <Box
            direction="row"
            pad={{ top: "medium", bottom: "xlarge" }}
            alignSelf="end"
            align="end"
          >
            <Heading
              level={1}
              size="large"
              style={{ fontWeight: "300", letterSpacing: "-0.033em" }}
            >
              Menus
            </Heading>
            <DemoToggle/>
          </Box>
          <Box gap="hair">{this.renderMenus()}</Box>
        </Box>
      </React.Fragment>
    );
  }
}

export default observer(Menus);
