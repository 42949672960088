import React from "react";
import { observer } from "mobx-react";
import { Box, Image } from "grommet";
import { Link } from "react-router-dom";

import logo from "../assets/logo.png";

class Footer extends React.Component {
  render() {
    return (
      <Box pad="50px" alignSelf="stretch" align="center">
        <Link to="/">
          <Image src={logo} alt="Touchtech" height="35px" width="131px" />
        </Link>
      </Box>
    );
  }
}

export default observer(Footer);
