import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Edit } from "grommet-icons";
import { Box, Button as GrommetButton } from "grommet";

class EditButton extends React.Component {
  constructor(props) {
    super(props);

    this.handlePress = this.handlePress.bind(this);
  }

  handlePress() {
    if (!this.props.onPress) {
      return;
    }

    this.props.onPress();
  }

  render() {
    const { margin, alignSelf, isDisabled, isLoading, onPress } = this.props;

    const canPress = onPress !== null && onPress !== undefined;

    return (
      <Box
        margin={margin}
        background="light-4"
        alignSelf={alignSelf}
        align="center"
        justify="center"
        width="32px"
        height="32px"
        round="16px"
        style={{ overflow: "hidden" }}
      >
        <GrommetButton
          hoverIndicator={true}
          disabled={isDisabled || isLoading || !canPress}
          onClick={this.handlePress}
          fill={true}
          plain
        >
          <Box
            margin={{ top: "1px" }}
            alignSelf="center"
            align="center"
            justify="center"
          >
            <Edit size="15px" />
          </Box>
        </GrommetButton>
      </Box>
    );
  }
}

EditButton.propTypes = {
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  alignSelf: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onPress: PropTypes.func
};

export default observer(EditButton);
