import { types, getRoot, getEnv, flow, getParent } from "mobx-state-tree";
import mapToList from "../../lib/mapToList";

const Localization = types.model("Localization", {
  languageCode: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
  value: types.string
});

const LocalizedString = types.model("LocalizedString", {
  defaultValue: types.string,
  localizations: types.array(Localization)
});

const OriginalPrice = types.model("OriginalPrice", {
  price: types.maybeNull(types.string),
  comparisonPrice: types.maybeNull(types.string)
});

const SalePrice = types.model("SalePrice", {
  price: types.maybeNull(types.string),
  comparisonPrice: types.maybeNull(types.string),
  validFromUtc: types.maybeNull(types.string),
  validToUtc: types.maybeNull(types.string)
});

const Price = types.model("Price", {
  currency: types.string,
  unit: types.maybeNull(LocalizedString),
  comparisonUnit: types.maybeNull(LocalizedString),
  originalPrice: types.maybeNull(OriginalPrice),
  salePrice: types.maybeNull(SalePrice)
});

const Media = types.model("Media", {
  type: types.string,
  url: types.string
});

const Category = types.model("Category", {
  id: types.identifier,
  ancestorIds: types.array(types.string),
  sortOrder: types.maybe(types.number)
});

const Attribute = types.model("Attribute", {
  attributeId: types.identifier,
  value: types.string
});

const Variant = types.model("Variant", {
  articleNumber: types.identifier,
  gtin: types.maybeNull(types.string),
  status: types.string,
  attributes: types.array(Attribute),
  media: types.array(Media),
  prices: types.array(Price)
});

const Product = types
  .model("Product", {
    id: types.string,
    articleNumber: types.identifier,
    status: types.string,
    prices: types.array(Price),
    media: types.array(Media),
    requiredChoices: types.array(types.string),
    name: types.maybeNull(LocalizedString),
    brand: types.maybeNull(LocalizedString),
    description: types.maybeNull(LocalizedString),
    categories: types.array(Category),
    attributes: types.array(Attribute),
    variants: types.array(Variant),
    json: types.frozen()
  })
  .views(self => ({
    get localeOptions() {
      const nameLocales = getLocales(self.name);
      const descriptionLocales = getLocales(self.description);
      const locales = [...new Set([...nameLocales, ...descriptionLocales])];

      return locales.map(locale => ({ key: locale, label: locale }));
    },
    get currencyOptions() {
      const currencies = self.prices.map(price => price.currency);
      return currencies.map(currency => ({ key: currency, label: currency }));
    },
    get images() {
      return self.media.filter(({ type }) => type === "image");
    },
    get previewImage() {
      return self.images.length > 0 ? self.images[0] : null;
    }
  }));

const Sku = types
  .model("Sku", {
    articleNumber: types.identifier,
    productArticleNumber: types.string
  })
  .views(self => ({
    get product() {
      const product = getParent(self, 2).items.get(self.productArticleNumber);
      return product || null;
    }
  }));

export default types
  .model("Products", {
    items: types.optional(types.map(Product), {}),
    skus: types.optional(types.map(Sku), {})
  })
  .views(self => ({
    get sortedItems() {
      return mapToList(self.items, true)
    },
  }))
  .actions(self => ({
    fetchByArticleNumber(articleNumber) {
      const { cancellationToken, cancel } = getEnv(
        self
      ).connector.getCancellationTokenSource();

      const action = flow(function* fetch() {
        const articleNumbers = articleNumber ? [articleNumber] : [];

        const products = yield getEnv(self).connector.fetchProducts(
          articleNumbers,
          cancellationToken
        );

        for (const product of products) {
          self.items.set(product.articleNumber, { ...product, json: product });

          for (const variant of product.variants) {
            self.skus.set(variant.articleNumber, {
              articleNumber: variant.articleNumber,
              productArticleNumber: product.articleNumber
            });
          }
        }
      });

      return getRoot(self).runAction(
        `fetchProduct-${articleNumber}`,
        action,
        cancel
      );
    }
  }));

function getLocales(localizedString) {
  const localeSet = new Set();

  if (!localizedString) {
    return localeSet;
  }

  for (const { languageCode, countryCode } of localizedString.localizations) {
    localeSet.add(`${languageCode}${countryCode ? `-${countryCode}` : ""}`);
  }

  return localeSet;
}
